import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getPerformance } from 'firebase/performance';
import 'firebase/analytics';
import { onLCP } from 'web-vitals/attribution';
import { trace } from 'firebase/performance';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_DEV_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
};

var fire = initializeApp(firebaseConfig);
export const analytics = getAnalytics(fire);
export const auth = getAuth(fire);
export const performance = getPerformance(fire);
export const database = getDatabase(fire);

export const trackLCP = () => {
  const lcpTrace = trace(performance, 'lcp_trace');

  onLCP((metric) => {
    // Start and stop Firebase trace for LCP
    lcpTrace.start();
    lcpTrace.putMetric('lcp', parseInt(metric.value.toFixed()));
    if (!!metric.attribution.element)
      lcpTrace.putAttribute('element', metric.attribution.element);
    lcpTrace.putAttribute(
      'elementRenderDelay',
      metric.attribution.elementRenderDelay.toFixed(0).toString(),
    );
    lcpTrace.putAttribute(
      'timeToFirstByte',
      metric.attribution.timeToFirstByte.toFixed(0).toString(),
    );
    lcpTrace.putAttribute(
      'resourceLoadDuration',
      metric.attribution.resourceLoadDuration.toFixed(0).toString(),
    );
    lcpTrace.putAttribute(
      'resourceLoadDelay',
      metric.attribution.resourceLoadDelay.toFixed(0).toString(),
    );
    if (!!metric.attribution.url)
      lcpTrace.putAttribute('url', metric.attribution.url);

    lcpTrace.stop();

    console.log(`LCP: ${metric.value}ms`);
    console.log(metric.attribution);
  });
};

export default fire;
