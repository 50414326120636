import { v4 as uuidv4 } from 'uuid';
import { Story } from '../typings/story';

export const createNewStory = (
  stageId: string,
  args?: {
    priority?: number;
    timestamp?: boolean;
    color?: string;
  },
): Story => {
  const { priority, timestamp, color } = args || {};
  if (!timestamp) {
    return {
      id: uuidv4(),
      color: color || "#64B5F6",
      priority: priority || 0,
      stageId: stageId,
      title: '',
    };
  }

  const time = new Date();

  return {
    id: uuidv4(),
    color: color || "#64B5F6",
    priority: priority || 0,
    stageId: stageId,
    title: '',
    createdAt: time,
    updatedAt: time,
  };
};
