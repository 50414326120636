import React, { useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light';

interface LottieProps {
  animationData: any; // You can replace `any` with the specific JSON type if available
  loop?: boolean;
  autoplay?: boolean;
  className?: string;
  style?: any;
}

const Lottie: React.FC<LottieProps> = ({ animationData, loop = true, autoplay = true, className, style }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animationInstance = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      animationInstance.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop,
        autoplay,
        animationData,
      });
    }

    return () => {
      animationInstance.current?.destroy();
    };
  }, [animationData, loop, autoplay]);

  return <div ref={containerRef} className={className} style={style} />;
};

export default Lottie;