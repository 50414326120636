import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './i18n/config';

import { DatabaseProvider } from './database/DatabaseProvider';
import { StoreProvider } from './store';
import './index.css';
import RouteKan from './route';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { trackLCP } from './services/firebase';
import FastSpringProvider from './store/FastSpringContext';

const Main = () => {
  useEffect(() => {
    trackLCP();
  }, []);

  return (
    <FastSpringProvider>
      <DatabaseProvider>
        <StoreProvider>
          <RouteKan />
        </StoreProvider>
      </DatabaseProvider>
    </FastSpringProvider>

  );
};

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<Main />);