import React, { useEffect, useState } from 'react';
import { Button, Modal } from "antd";
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import TranslateAuth from './TranslateAuth';
import StyledFirebaseAuth from '../components/StyledFirebaseAuth';
import { auth } from '../services/firebase';
import { TermsAndConditions } from '../components/TermsAndConditions';
import { CloseOutlined } from '@ant-design/icons';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import Service from '../api/Service';
import AppleLogo from '../assets/svg/apple.svg';
import MailLogo from '../assets/svg/mail.svg';

import ReactGA from 'react-ga';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CDN } from '../utils/constants';
import { isAnonymous } from '../utils/users';
import Lottie from '../components/Lottie';
import { defaultLottieConfig } from '../utils/lottie';
import loadingAnimation from '../assets/animation/loading.json'

type Props = {
  open: boolean,
  onCancel: () => void,
  config?: any,
  language: string,
  handleCredentialResponse?: any,
  isDesktop: boolean,
  component?: any,
  callbackSignInSucceeded: (accountId: string) => void;
  loading?: boolean;
}

const SignupModal = ({ open, onCancel, language, isDesktop, handleCredentialResponse, loading, callbackSignInSucceeded }: Props) => {
  const { t } = useTranslation();
  const [loadingButtons, setLoadingButtons] = useState<boolean>(false);

  useEffect(() => {
    setLoadingButtons(loading ?? false);
  }, [loading]);

  useEffect(() => {
    if(open === false) {
      setLoadingButtons(false);
    }
  }, [open])

  let signInFlow = isDesktop ? 'redirect' : 'popup';
  let signInOptions: any = [
    { provider: 'apple.com', iconUrl: AppleLogo },
  ];

  const signInCallback = (authResult: any, redirectUrl: string) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      currentUser
        .getIdToken(true)
        .then(function (idToken) {
          setLoadingButtons(true);
          Service.CreateNewUser(idToken, currentUser.uid, response => {
            if (response) {
              callbackSignInSucceeded(response);
              if (isDesktopApp()) {
                // Only report the "Login" event if it occurred from the SignUp page
                // Check if "Login" event has been sent
                let login_key = "td_kanbana_event_login";
                let login_check = localStorage.getItem(login_key);
                if (!login_check) {
                  // If we have not sent the event, send it now
                  let distributor = localStorage.getItem("td_kanbana_distributor") ?? "Unknown";
                  fetch("https://web-api.kanbana.com/desktopEvent?d=" + distributor + "&e=Login");
                  localStorage.setItem(login_key, "sent");
                }
              }

              ReactGA.event({
                category: 'signIn',
                action: 'signInSucceeded',
              });
            }
          });
        })
        .catch(function (error) {
          alert(error);
        });
    }

    return false;
  };

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: signInCallback
    },
    signInFlow: signInFlow,
    signInOptions: signInOptions
  };

  if (isDesktop) {
    signInOptions.unshift({
      provider: EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
      iconUrl: MailLogo
    });
  } else {
    signInOptions.unshift({
      provider: EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
      iconUrl: MailLogo
    })
    signInOptions.unshift(GoogleAuthProvider.PROVIDER_ID);
  }

  const initializeGsi = () => {
    if (isDesktopApp()) {
      let scriptId = "gsiScript";
      if (document.getElementById(scriptId)) {

        (window as any).google.accounts.id.initialize({
          client_id: "952297686491-i8ej3ffcpk52gpi62s5un911jhkmnkc7.apps.googleusercontent.com",
          auto_select: true,
          // hd: '*',
          callback: (data: any) => handleCredentialResponse(data, this)
        });

        setTimeout(() => renderGoogleButton(), 200);
      }
      else {
        const script = document.createElement("script");
        script.setAttribute("id", scriptId)
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        document.body.appendChild(script);

        document.getElementById(scriptId)!.addEventListener("load", () => {

          (window as any).google.accounts.id.initialize({
            client_id: "952297686491-i8ej3ffcpk52gpi62s5un911jhkmnkc7.apps.googleusercontent.com",
            auto_select: true,
            // hd: '*',
            callback: (data: any) => handleCredentialResponse(data, this)
          });

          setTimeout(() => renderGoogleButton(), 200);
        });
      }
    }
  };

  useEffect(() => {
    if (isDesktop && language && open) {
      initializeGsi();
    }
  }, [isDesktop, language, open, initializeGsi]);

  const renderGoogleButton = () => {
    (window as any).google.accounts.id.renderButton(
      document.getElementById("googleLogin")!,
      { theme: "filled_blue", size: "large", width: 290, text: 'continue_with', logo_alignment: 'left', locale: localStorage.getItem('lang') || navigator.language }  // customization attributes
    );
    (window as any).google.accounts.id.prompt();
  };

  const googleLoginStyles = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  };

  return (<Modal destroyOnClose styles={{ content: { padding: 0 } }} closable={false} open={open} footer={false} title={false} width={700}>
    <ButtonFloat shape="circle" onClick={onCancel} icon={<CloseOutlined />} />
    <Content>
      <LeftSideDiv isDesktop={isDesktop}>
        <Header>{t(isAnonymous() ? 'Board.SaveMyData' : 'Auth.LoginInSeconds')}</Header>
        <SubHeader>{t('Auth.UseYourEmail')}</SubHeader>
        {!loadingButtons ? <TranslateAuth type="SignUp" uiCallBack={(showGoogleButton) => {

          var googleButton = document.getElementById("googleLogin");
          var googleButtonLoginTextOr = document.getElementById("googleLoginTextOr");
          if (googleButton && isDesktop) {
            googleButton.style.display = showGoogleButton ? "flex" : "none";
          }

          if (googleButtonLoginTextOr && isDesktop) {
            googleButtonLoginTextOr.style.display = showGoogleButton ? "flex" : "none";
          }
        }
        }>
          {isDesktop && <div style={{ marginTop: 40 }}>
            <div id="googleLogin" className='googleLogin' style={googleLoginStyles}></div>
            <span id='googleLoginTextOr' style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', marginTop: 15, alignItems: 'center' }}>
              <div style={{ border: '1px solid #ccc', width: '40%', height: 1, marginRight: 5 }} />
              {t('Auth.Or')}
              <div style={{ border: '1px solid #ccc', width: '40%', height: 1, marginLeft: 5 }} />
            </span>
          </div>}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'left' }}>
            <StyledFirebaseAuth
              key={Math.random()}
              uiConfig={uiConfig}
              firebaseAuth={auth}
              className="firebase-auth"
            />
          </div>
        </TranslateAuth>
          : (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 40}}>
              <span>{t('Auth.LoadingSync')}</span>
              <Lottie
                style={{ height: 80, width: 80 }}
                {...defaultLottieConfig(loadingAnimation)}
                loop={true}
              />
            </div>)}
        <div style={{ padding: '30px 0 10px 0', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <TermsAndConditions language={language ?? 'en'} t={t} />
        </div>
      </LeftSideDiv>
      <div style={{
        flex: '1',
        minWidth: '300px'
      }}>
        <LazyLoadImage style={{ height: '100%', borderRadius: '0 8px 8px 0', width: '100%', verticalAlign: 'middle' }} src={`${CDN}/images/testimonial_image_2.jpg`} />
      </div>
    </Content>
  </Modal >)
}

const ButtonFloat = styled(Button)`
import { CDN } from '../utils/constants';
  position: absolute;
  right: -40px;

  @media (max-width: 768px) {
    right: 6px;
    top: 6px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const LeftSideDiv = styled.div<{ isDesktop: boolean }>`
  flex: 1;
  padding: 0 6px;
  display: flex;
  flex-direction: column;
  min-width: 300px;

  .firebaseui-list-item > button {
    width: 100%;
    max-width: initial;
    display: flex;

    align-items: center;
    border-radius: 8px;
    background-color: #ffffff !important;
    font-weight: 500;
    font-family: Poppins,sans-serif;

    > span {
      color: #000000;
    }
  }

  .firebaseui-id-idp-button {
    background-color: white !important;
    box-shadow: ${(props) => (props.isDesktop ? `none` : `0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12) !important`)};
    border: ${(props) => (!props.isDesktop ? `none` : `1px solid #dedce0`)};
    > span {
      color: #3c4043 !important;
      font-family: "Google Sans",arial,sans-serif;
    }
  }

  .firebaseui-idp-text-long {
    padding-left: 5px !important;
    margin: auto !important;
  }

  .googleLogin {
    display: flex;
    width: 340px !important;
    justify-content: flex-end;
  }
  .nsm7Bb-HzV7m-LgbsSe {
    padding: 0px !important;
  }
`;

const Header = styled.h1`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  padding: 0 25px;
`;

const SubHeader = styled.h2`
  color: #161f25;
  font-size: 14px;
  font-weight: 400;
  padding: 0 25px;
`;

export default SignupModal;