import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace) => {
      console.log('resourcesToBackend', language, namespace);
      return import(`./locales/${language}/${namespace}.json`);
    }),
  )
  .on('failedLoading', (lng, ns, msg) => console.error(msg))
  .init({
    fallbackLng: 'en',
    lng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
  });

i18n.languages = ['en', 'es','hi', 'fr','de', 'ja', 'ko', 'pt', 'ru'];

export default i18n;
