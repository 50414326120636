// @flow
import { colors } from '../theme';

export class DomainViewModelBase {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;

  constructor() {
    this.id = '';
  }

  initialize(id: string, createdAt?: Date, updatedAt?: Date, deletedAt?: Date) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
  }
}

export class StoryViewModel extends DomainViewModelBase {
  color: string;
  completedAt?: Date;
  isEditMode: boolean;
  originalColor: string;
  originalTitle: string;
  priority: number;
  stageId: string;
  title: string;

  constructor() {
    super();
    this.color = colors.task.grey;
    this.isEditMode = false;
    this.originalColor = '';
    this.originalTitle = '';
    this.priority = 0;
    this.stageId = '';
    this.title = '';
  }

  // $FlowFixMe
  initialize(
    id: string,
    createdAt?: Date,
    updatedAt?: Date,
    deletedAt?: Date,
    title?: string,
    priority?: number,
    color?: string,
    completedAt?: Date,
    stageId?: string,
  ) {
    super.initialize(id, createdAt, updatedAt, deletedAt);
    this.color = color || '';
    this.completedAt = completedAt;
    this.originalColor = color || '';
    this.originalTitle = title || '';
    this.priority = priority || 0;
    this.stageId = stageId || '';
    this.title = title || '';
  }
}

export class StageViewModel extends DomainViewModelBase {
  boardId: string;
  isEditMode: boolean;
  isLast: boolean;
  originalTitle: string;
  priority: number;
  stories: Array<StoryViewModel>;
  storyLimit: number;
  title: string;

  constructor() {
    super();
    this.boardId = '';
    this.isEditMode = false;
    this.isLast = true;
    this.originalTitle = '';
    this.priority = 0;
    this.stories = [];
    this.storyLimit = 10;
    this.title = '';
  }

  // $FlowFixMe
  initialize(
    id: string,
    createdAt?: Date,
    updatedAt?: Date,
    deletedAt?: Date,
    title?: string,
    priority?: number,
    storyLimit?: number,
    boardId?: string,
    stories?: Array<StoryViewModel>,
  ) {
    super.initialize(id, createdAt, updatedAt, deletedAt);
    this.boardId = boardId || '';
    this.originalTitle = title || '';
    this.priority = priority || 0;
    this.stories = stories || [];
    this.storyLimit = storyLimit || 10;
    this.title = title || '';
  }
}

export class BoardViewModel extends DomainViewModelBase {
  accountId: string;
  id: string;
  isLoading: boolean;
  stages: Array<StageViewModel>;
  title: string;

  constructor() {
    super();
    this.accountId = '';
    this.id = 'init';
    this.isLoading = false;
    this.stages = [];
    this.title = '';
  }

  // $FlowFixMe
  initialize(
    id: string,
    createdAt?: Date,
    updatedAt?: Date,
    deletedAt?: Date,
    title?: string,
    accountId?: string,
    stages?: Array<StageViewModel>,
    isLoading?: boolean,
  ) {
    super.initialize(id, createdAt, updatedAt, deletedAt);
    this.accountId = accountId || '';
    this.isLoading = !!isLoading;
    this.stages = stages || [];
    this.title = title || '';
  }
}

export type AppNavigation = 'signup' | 'forgotpassword' | 'board' | 'login' | 'forgothowtologgedin';

export type PeriodCompletedCards = {
  periodType: 'Days' | 'Months' | "Years",
  period: number
};