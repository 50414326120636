import isNil from 'lodash/isNil';
import { Board } from '../typings/board';
import { Stage } from '../typings/stage';
import { State } from '../typings/state';
import { Story } from '../typings/story';

export const mapStagesArray = (stages: Stage[] = []): State['stages'] => {
  const obj: State['stages'] = {};
  for (let i = 0; i < stages.length; i++) {
    const stage = stages[i];
    obj[stage.id] = stage;
  }

  return obj;
};

export const mapStagesArrayIndex = (
  stages: Stage[] = [],
): State['stagesIndex'] => {
  const obj: State['stagesIndex'] = {};
  for (let i = 0; i < stages.length; i++) {
    const stage = stages[i];
    obj[stage.boardId] = {
      ...obj[stage.boardId],
      [stage.id]: 1,
    };
  }

  return obj;
};

export const mapStoriesArray = (stories: Story[] = []): State['stories'] => {
  const obj: State['stories'] = {};
  for (let i = 0; i < stories.length; i++) {
    const story = stories[i];
    obj[story.id] = story;
  }

  return obj;
};

export const mapStoriesArrayIndex = (
  stories: Story[] = [],
): State['storiesIndex'] => {
  const obj: State['storiesIndex'] = {};
  for (let i = 0; i < stories.length; i++) {
    const story = stories[i];
    obj[story.stageId] = {
      ...obj[story.stageId],
      [story.id]: 1,
    };
  }
  return obj;
};

export const updateBoardPriorities = (boards: Board[]): Board[] => {
  const timestamp = new Date();

  const updatedBoards: Board[] = [];
  for (let i = 0; i < boards.length; i++) {
    const board = boards[i];
    if (board.priority !== i) {
      board.updatedAt = timestamp;
      board.priority = i;
    }
    updatedBoards.push(board);
  }

  return updatedBoards;
};

export const updateStagePriorities = (stages: Stage[]): Stage[] => {
  const timestamp = new Date();

  const updatedStages: Stage[] = [];
  for (let i = 0; i < stages.length; i++) {
    const stage = stages[i];
    if (stage.priority !== i) {
      stage.updatedAt = timestamp;
      stage.priority = i;
    }
    if (i !== stages.length - 1) {
      stage.isLast = false;
    } else {
      stage.isLast = true;
    }
    updatedStages.push(stage);
  }

  return updatedStages;
};

export const updateStoryPriorities = (stories: Story[]) => {
  const timestamp = new Date();

  const updatedStories: Story[] = [];
  const storiesToCompare = [...stories];
  for (let i = 0; i < storiesToCompare.length; i++) {
    const story = storiesToCompare[i];
    if (isNil(story)) continue;

    if (story.priority !== i) {
      story.priority = i;
      story.updatedAt = timestamp;
    }
    updatedStories.push(story);
  }

  return updatedStories;
};

export const HasEmptyText = (str: string) => {
  const len = str.replace(/[\r\n]/g, '').trim().length;
  return len === 0;
};