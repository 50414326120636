import React, { FunctionComponent, ReactNode } from 'react';
import { UserData } from '../account/AccountPanel';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../services/firebase';
import Service from '../api/Service';
import isEmpty from 'lodash-es/isEmpty';
import { getAccountId, isAnonymous } from '../utils/users';
import { isNil } from 'lodash-es';

interface BaseLayoutProps {
  children?: ReactNode;
}

export const FastSpringContext = React.createContext<any | null>(null);

const FastSpringProvider: FunctionComponent<BaseLayoutProps> = ({
  children,
}) => {
  const [plans, setPlans] = React.useState<any[]>([]);
  const [usersPlans, setUsersPlans] = React.useState<any[]>([]);
  const [email, setEmail] = React.useState<string>('');

  const addPlans = (newPlans: any) => {
    const newItems = newPlans.reverse();
    setPlans(newItems);
    populateUserPlans(newItems);
  };

  const populateUserPlans = async (newItems: any[]) => {
    const accountId = getAccountId();

    const userData = await fetchUserData().catch(() => {
      console.log('Could not load user data');
    });

    if (!userData) {
      return;
    }

    if (isEmpty(newItems) || isNil(accountId) || isAnonymous()) return [];

    const userFastSpringPlans = await Service.getFastSpringUserPlans(accountId);
    setEmail(userData.email);
    localStorage.setItem('UserPlans', JSON.stringify(userFastSpringPlans))
    const fastSpringPlans = newItems.filter((plan: any) =>
      userFastSpringPlans?.plansIds.includes(plan.pid),
    );
    if (usersPlans.length === 0 && fastSpringPlans.length === 0){
      setUsersPlans(fastSpringPlans);
      
    }
      

    return fastSpringPlans;
  };

  const fetchUserData = async (): Promise<UserData> => {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve({
            uid: user?.uid ?? '',
            email: user?.email ?? '',
            providerId: user?.providerData[0]?.providerId ?? '',
          });
        } else {
          console.error('Error retrieving user data');
          reject();
        }
        // unsubscribe from the listener
        unsubscribe();
      });
    });
  };

  const getUsersPlan = async () => {
    if (!isEmpty(usersPlans)) return usersPlans;

    const storagePlans = localStorage.getItem('AppPlans')
    const allPlans = !isEmpty(plans) ? plans : JSON.parse(storagePlans ?? '{}');
    const newUsersPlans = await populateUserPlans(allPlans);
    return newUsersPlans;
  };

  return (
    <FastSpringContext.Provider
      value={{ plans, usersPlans, email, getUsersPlan, addPlans }}>
      {children}
    </FastSpringContext.Provider>
  );
};

export default FastSpringProvider;
