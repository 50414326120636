import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import axios from 'axios';
import * as uuid from 'uuid';
import { ANONYMOUS } from '../typings/auth';
import { isNil } from 'lodash-es';

export const generateInstance = () => {
  let syncId = sessionStorage.getItem('com.kanbana.syncId');
  if (!syncId) {
    syncId = uuid.v1();
    sessionStorage.setItem('com.kanbana.syncId', syncId);
  }
  let languageCode = localStorage.getItem('lang');
  let distributor = localStorage.getItem('td_kanbana_distributor') ?? '';
  let distributorInstallDate =
    localStorage.getItem('td_kanbana_distributor_date') ?? '';

  let userAgent = window.navigator.userAgent;
  let operatingSystem = 'Unknown OS';

  if (userAgent.indexOf('Win') !== -1) {
    operatingSystem = 'Windows';
  } else if (userAgent.indexOf('Mac') !== -1) {
    operatingSystem = 'Mac';
  } else if (userAgent.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX';
  } else if (userAgent.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux';
  } else if (/Android/.test(userAgent)) {
    operatingSystem = 'Android';
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    operatingSystem = 'iOS';
  }

  const instance = axios.create({
    baseURL: process.env.REACT_APP_KANBANA_SERVICE_URL,
    timeout: 30000, // 30 seconds
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': '28DB0B2C-C3A9-4641-AC1E-AC08DE632845',
      'X-Client-Device': isDesktopApp()
        ? `Desktop${operatingSystem}`
        : 'Browser',
      'X-Client-Os': operatingSystem,
      'X-Client-SyncId': syncId,
      'X-Client-LanguageCode': languageCode,
      'X-Client-Distributor': distributor,
      'X-Client-DistributorInstallDate': distributorInstallDate,
    },
  });

  instance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    (error) => {
      if (error?.data?.message === 'Request canceled by interceptor') {
        return Promise.resolve(error);
      }

      return Promise.reject(error);
    },
  );

  return instance;
};