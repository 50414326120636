// reducer is where we handle the state changes according to the action type and payload we dispatched
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';

import { Board } from '../typings/board';
import { Stage } from '../typings/stage';
import { Action, State } from '../typings/state';
import { Story } from '../typings/story';
import { Dictionary } from '../typings/utils';
import { arrayMove } from '../utils/array';
import {
  HasEmptyText,
  mapStagesArray,
  mapStoriesArray,
  updateBoardPriorities,
  updateStagePriorities,
  updateStoryPriorities,
} from '../utils/board';
import {
  ADD_BOARD_LOCALLY,
  ADD_STAGE_LOCALLY,
  ADD_STORY_LOCALLY,
  BLUR_STAGE_LOCALLY,
  BLUR_STORY_LOCALLY,
  DELETE_BOARD_LOCALLY,
  DELETE_STAGE_LOCALLY,
  DELETE_STORY_LOCALLY,
  GET_BOARD_SUCCESS,
  FOCUS_STAGE_LOCALLY,
  FOCUS_STORY_LOCALLY,
  LOAD_BOARDS_SUCCESS,
  LOAD_STAGES_SUCCESS,
  LOAD_STORIES_SUCCESS,
  RESET_STATE,
  SET_ACCOUNT_ID,
  SET_ACTIVE_BOARD,
  SET_LAST_SYNCHRONIZED_AT,
  UPDATE_BOARDS_LOCALLY,
  UPDATE_STAGE_LOCALLY,
  UPDATE_STAGES_LOCALLY,
  UPDATE_STORY_LOCALLY,
  UPDATE_BOARD_LOCALLY,
  COMPLETE_STORY_LOCALLY,
  OPEN_PREMIUM_MODAL,
  MOVE_CARD_SAME_COLUMN,
  MOVE_CARD_DIFFERENT_COLUMN,
} from './actions';
import { getStageStories } from './computed';

export const initialState: State = {
  boards: {},
  stages: {},
  stories: {},
  stagesIndex: {},
  storiesIndex: {},
  isMenuExpanded: false,
  isBoardsExpanded: false,
  internalUpdateDate: undefined,
  openPremiumModal: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // account
    case SET_ACCOUNT_ID: {
      const accountId = action.payload;
      return {
        ...state,
        accountId,
      };
    }

    // board
    case GET_BOARD_SUCCESS: {
      const board = action.payload;
      return {
        ...state,
        boards: {
          ...state.boards,
          [board.id]: {
            ...(state.boards[board.id] ? state.boards[board.id] : {}),
            ...board,
          },
        },
      };
    }
    case LOAD_BOARDS_SUCCESS: {
      const { payload = [] }: { payload: Board[] } = action;
      const boards = {
        ...state.boards,
      };
      for (let i = 0; i < payload.length; i++) {
        const board = payload[i];
        boards[board.id] = board;
      }
      return {
        ...state,
        boards,
      };
    }
    case LOAD_STAGES_SUCCESS: {
      const { payload = [] }: { payload: Stage[] } = action;
      const newStagesIndex = { ...state.stagesIndex };
      for (let i = 0; i < payload.length; i++) {
        const stage = payload[i];
        const storedStage = state.stages[stage.id];
        if (storedStage && storedStage.boardId !== stage.boardId) {
          const newBoardStagesIndex: Dictionary<number> = {};
          const storedStageIds = Object.keys(
            newStagesIndex[storedStage.boardId],
          );
          for (let i = 0; i < storedStageIds.length; i++) {
            const stageId = storedStageIds[i];
            if (stageId === storedStage.id) {
              continue;
            }
            newBoardStagesIndex[stageId] = 1;
          }
          newStagesIndex[storedStage.boardId] = newBoardStagesIndex;
        }
        if (!newStagesIndex[stage.boardId]) {
          newStagesIndex[stage.boardId] = {
            [stage.id]: 1,
          };
        } else {
          newStagesIndex[stage.boardId][stage.id] = 1;
        }
      }
      const data = {
        ...state,
        stages: {
          ...state.stages,
          ...mapStagesArray(payload),
        },
        stagesIndex: newStagesIndex,
      };
      return data;
    }
    case LOAD_STORIES_SUCCESS: {
      const { payload = [] }: { payload: Story[] } = action;
      const newStoriessIndex = { ...state.storiesIndex };
      for (let i = 0; i < payload.length; i++) {
        const story = payload[i];
        const storedStories = state.stories[story.id];
        if (storedStories && storedStories.stageId !== story.stageId) {
          const newBoardStoriessIndex: Dictionary<number> = {};
          const storedStoriesIds = Object.keys(
            newStoriessIndex[storedStories.stageId],
          );
          for (let i = 0; i < storedStoriesIds.length; i++) {
            const storyId = storedStoriesIds[i];
            if (storyId === storedStories.id) {
              continue;
            }
            newBoardStoriessIndex[storyId] = 1;
          }
          newStoriessIndex[storedStories.stageId] = newBoardStoriessIndex;
        }
        if (!newStoriessIndex[story.stageId]) {
          newStoriessIndex[story.stageId] = {
            [story.id]: 1,
          };
        } else {
          newStoriessIndex[story.stageId][story.id] = 1;
        }
      }
      const data = {
        ...state,
        stories: {
          ...state.stories,
          ...mapStoriesArray(payload),
        },
        storiesIndex: newStoriessIndex,
      };
      return data;
    }
    case SET_ACTIVE_BOARD: {
      const { payload }: { payload: string } = action; // payload: activeBoardId
      return {
        ...state,
        activeBoardId: payload,
      };
    }

    // board
    case ADD_BOARD_LOCALLY: {
      const { payload }: { payload: Board } = action;
      const { id } = payload;
      return {
        ...state,
        boards: {
          ...state.boards,
          [id]: payload,
        },
        internalUpdateDate: new Date(),
      };
    }
    case DELETE_BOARD_LOCALLY: {
      const { payload }: { payload: string } = action;
      const { boards } = state;

      if (!boards[payload]) {
        return state;
      }

      const timestamp = new Date();
      const board = {
        ...boards[payload],
        deletedAt: timestamp,
        updatedAt: timestamp,
      };

      const oldBoards: Board[] = Object.values(boards).sort(
        (a, b) => a.priority - b.priority,
      );
      const newBoards: Board[] = []; // without deleted board
      for (let i = 0; i < oldBoards.length; i++) {
        const b = oldBoards[i];
        if (b.id === payload) {
          continue;
        }
        if (b.priority < board.priority) {
          newBoards.push(b);
        } else {
          newBoards.push({
            ...b,
            updatedAt: new Date(),
          });
        }
      }
      const updatedNewBoards = [...updateBoardPriorities(newBoards), board]; // add the deleted board back in order to call sync API

      return {
        ...state,
        boards: {
          ...state.boards,
          ...updatedNewBoards.reduce(
            (obj, board) => ({
              ...obj,
              [board.id]: board,
            }),
            {},
          ),
          payload: board,
        },
        internalUpdateDate: new Date(),
      };
    }
    case UPDATE_BOARD_LOCALLY: {
      const { payload }: { payload: Board } = action;

      if (!payload) {
        return state;
      }
      payload.updatedAt = new Date();
      return {
        ...state,
        boards: {
          ...state.boards,
          [payload.id]: payload,
        },
        internalUpdateDate: new Date(),
      };
    }
    case UPDATE_BOARDS_LOCALLY: {
      const { payload }: { payload: { boards: Board[] } } = action;
      const { boards = [] } = payload;

      if (!boards.length) {
        return state;
      }

      return {
        ...state,
        boards: {
          ...state.boards,
          ...boards.reduce(
            (obj, board) => ({
              ...obj,
              [board.id]: board,
            }),
            {},
          ),
        },
        internalUpdateDate: new Date(),
      };
    }

    // stage
    case ADD_STAGE_LOCALLY: {
      const { payload }: { payload: Stage } = action;
      const { boardId, id } = payload;
      const { stages, stagesIndex } = state;

      return {
        ...state,
        stages: {
          ...stages,
          [id]: payload,
        },
        stagesIndex: {
          ...stagesIndex,
          [boardId]: {
            ...stagesIndex[boardId],
            [id]: 1,
          },
        },
        internalUpdateDate: payload.title !== '' ? new Date() : state.internalUpdateDate,
      };
    }
    case BLUR_STAGE_LOCALLY: {
      return {
        ...state,
        focusStageId: undefined,
      };
    }
    case DELETE_STAGE_LOCALLY: {
      const { payload }: { payload: Stage } = action;
      const { boardId, id } = payload;
      const { stages, stagesIndex } = state;

      const stageIds = Object.keys(stagesIndex[boardId]);
      const stagesObj: Dictionary<Stage> = {};
      for (let i = 0; i < stageIds.length; i++) {
        const stageId = stageIds[i];
        if (!stages[stageId] || !!stages[stageId].deletedAt) {
          continue;
        }
        stagesObj[stageId] = stages[stageId];
      }

      const toBeDeleted: Stage | undefined = stagesObj
        ? stagesObj[id]
        : undefined;

      if (!toBeDeleted) {
        return state;
      }
      const timestamp = new Date();
      toBeDeleted.deletedAt = timestamp;
      toBeDeleted.updatedAt = timestamp;

      const oldStages: Stage[] = Object.values(stagesObj).sort(
        (a, b) => a.priority - b.priority,
      );
      const newStages: Stage[] = []; // without deleted stage
      for (let i = 0; i < oldStages.length; i++) {
        const stage = oldStages[i];
        if (stage.id === id) {
          continue;
        }
        if (stage.priority < toBeDeleted.priority) {
          newStages.push(stage);
        } else {
          newStages.push({
            ...stage,
            updatedAt: new Date(),
          });
        }
      }
      const updatedNewStages = [
        ...updateStagePriorities(newStages),
        toBeDeleted,
      ]; // add the deleted stage back in order to call sync API

      return {
        ...state,
        stages: {
          ...state.stages,
          ...mapStagesArray(updatedNewStages),
        },
        internalUpdateDate: new Date(),
      };
    }
    case FOCUS_STAGE_LOCALLY: {
      const { payload }: { payload: Stage } = action;
      return {
        ...state,
        focusStageId: payload.id,
      };
    }
    case UPDATE_STAGE_LOCALLY: {
      const { payload }: { payload: Stage } = action;
      const { id } = payload;
      const { stages } = state;

      return {
        ...state,
        focusStageId: undefined,
        stages: {
          ...stages,
          [id]: { ...payload, updatedAt: new Date() },
        },
        internalUpdateDate: new Date(),
      };
    }
    case UPDATE_STAGES_LOCALLY: {
      const { payload }: { payload: { stages: Stage[] } } = action;
      const { stages = [] } = payload;

      if (!stages.length) {
        return state;
      }

      const { stagesIndex } = state;
      return {
        ...state,
        stages: {
          ...state.stages,
          ...stages.reduce(
            (obj, stage) => ({
              ...obj,
              [stage.id]: stage,
            }),
            {},
          ),
        },
        stagesIndex: {
          ...stagesIndex,
          ...stages.reduce(
            (obj, stage) => ({
              ...obj,
              [stage.boardId]: {
                ...stagesIndex[stage.boardId],
                [stage.id]: 1,
              },
            }),
            {},
          ),
        },
        internalUpdateDate: new Date(),
      };
    }

    // story
    case ADD_STORY_LOCALLY: {
      const { payload }: { payload: Story } = action;
      const { id, stageId } = payload;
      const { stages, stories, storiesIndex } = state;

      const newStoriesIndex = cloneDeep(storiesIndex);

      if (!newStoriesIndex[stageId]) {
        newStoriesIndex[stageId] = {};
      }

      const stageStories: Story[] = getStageStories(
        stories,
        storiesIndex,
        stages[stageId],
      );

      const boardId = stages[stageId].boardId;
      const board = state.boards[boardId];

      const newStories = [...stageStories];
      // If priority > 0 => add to the end of the list else add to the beginning of the list
      if (payload.priority) {
        newStories.splice(payload.priority, 0, payload);
      } else {
        newStories.unshift(payload);
      }
      const updatedStories = updateStoryPriorities(newStories);
      newStoriesIndex[stageId][id] = 1;
      return {
        ...state,
        focusStoryId: id,
        stories: {
          ...state.stories,
          ...updatedStories.reduce(
            (obj, item) => ({
              ...obj,
              [item.id]: item,
            }),
            {},
          ),
        },
        storiesIndex: {
          ...newStoriesIndex,
        },
        internalUpdateDate: payload.title !== '' ? new Date() : state.internalUpdateDate,
        boards: {
          ...state.boards,
          [boardId]: {
            ...board,
            lastStoryId: id,
          },
        },
      };
    }
    case BLUR_STORY_LOCALLY: {
      return {
        ...state,
        focusStoryId: undefined,
      };
    }
    case DELETE_STORY_LOCALLY: {
      const { payload }: { payload: Story } = action;
      const { id, stageId } = payload;
      const { stages, stories, storiesIndex } = state;

      const newStoriesIndex = cloneDeep(storiesIndex);
      const oldStory = state.stories[id];
      if (!oldStory || !newStoriesIndex[stageId]) {
        return state;
      }
      const timestamp = new Date();
      oldStory.deletedAt = timestamp;
      oldStory.updatedAt = timestamp;
      oldStory.priority = 999;

      const newStories = getStageStories(
        stories,
        storiesIndex,
        stages[stageId],
      );
      const updatedStories = updateStoryPriorities([...newStories, oldStory]);

      return {
        ...state,
        stories: {
          ...state.stories,
          ...mapStoriesArray(updatedStories),
          [id]: { ...oldStory, priority: 999 },
        },
        internalUpdateDate: new Date(),
      };
    }
    case COMPLETE_STORY_LOCALLY: {
      const { payload }: { payload: Story } = action;
      const { id, stageId } = payload;
      const { stages, stories, storiesIndex } = state;

      const newStoriesIndex = cloneDeep(storiesIndex);
      const oldStory = state.stories[id];
      if (!oldStory || !newStoriesIndex[stageId]) {
        return state;
      }
      const timestamp = new Date();
      oldStory.completedAt = timestamp;
      oldStory.updatedAt = timestamp;
      oldStory.priority = 999;

      const newStories = getStageStories(
        stories,
        storiesIndex,
        stages[stageId],
      );
      const updatedStories = updateStoryPriorities([...newStories, oldStory]);

      return {
        ...state,
        stories: {
          ...state.stories,
          ...mapStoriesArray(updatedStories),
          [id]: { ...oldStory, priority: 999 },
        },
        internalUpdateDate: new Date(),
      };
    }
    case OPEN_PREMIUM_MODAL: {
      const { payload }: { payload: boolean } = action; // payload: activeBoardId
      return {
        ...state,
        openPremiumModal: payload,
      };
    }
    case FOCUS_STORY_LOCALLY: {
      const { payload }: { payload: Story } = action;
      return {
        ...state,
        focusStoryId: payload.id,
      };
    }
    case MOVE_CARD_SAME_COLUMN: {
      const { payload }: { payload: { story: Story, cards: Story[], toIndex: number } } = action;

      const newStory = { ...payload.story };
      newStory.priority = payload.toIndex;
      newStory.updatedAt = new Date();
      let cardsInTheSameColumnWithoutSelectedCard = payload.cards.filter(t => t.id !== payload.story.id);
      let cardsInTheSameColumn = orderBy(cardsInTheSameColumnWithoutSelectedCard, 'priority');

      let count = 0;

      for (let index = 0; index < cardsInTheSameColumn.length; index++) {
        if (cardsInTheSameColumn[index].id === payload.story.id) continue;
        if (HasEmptyText(cardsInTheSameColumn[index].title)) {
          // Delete this card!
          cardsInTheSameColumn[index].priority = 1000;
          cardsInTheSameColumn[index].updatedAt = new Date();
          cardsInTheSameColumn[index].deletedAt = new Date();
          continue;
        }
        if (index === payload.toIndex) {
          count++;
          cardsInTheSameColumn[index].priority = count;
          cardsInTheSameColumn[index].updatedAt = new Date();
          count++;
          continue;
        }
        cardsInTheSameColumn[index].priority = count;
        cardsInTheSameColumn[index].updatedAt = new Date();
        count++;
      }
      let dictionary: Dictionary<Story> = {};
      cardsInTheSameColumn.forEach(item => {
        dictionary[item.id] = item;
      })

      return {
        ...state,
        stories: {
          ...state.stories,
          ...dictionary,
          [payload.story.id]: { ...payload.story, updatedAt: new Date() },
        },
        internalUpdateDate: new Date(),
      }
    }

    case MOVE_CARD_DIFFERENT_COLUMN: {
      const { payload }: {
        payload: {
          story: Story, cardsInitialList: Story[], cardsEndList: Story[], toIndex: number
        }
      } = action;

      const newStory = { ...payload.story };
      newStory.priority = payload.toIndex;
      newStory.updatedAt = new Date();
      let cardsInOldColumns = payload.cardsInitialList.filter(t => t.id !== payload.story.id);
      let sortedCardsInTheOldColumn = orderBy(cardsInOldColumns, 'priority');

      let oldCardsDictionary: Dictionary<Story> = {};
      sortedCardsInTheOldColumn.forEach(item => {
        oldCardsDictionary[item.id] = item;
      })

      let cardsInNewColumns = payload.cardsEndList.filter(t => t.id !== payload.story.id);
      let sortedCardsInTheNewColumn = orderBy(cardsInNewColumns, 'priority');

      let count = 0;

      for (let index = 0; index < sortedCardsInTheNewColumn.length; index++) {
        const card = sortedCardsInTheNewColumn[index];
        if (card.id === payload.story.id) continue;
        if (HasEmptyText(card.title)) {
          // Delete this card!
          card.priority = 1000;
          card.updatedAt = new Date();
          card.deletedAt = new Date();
          continue;
        }
        if (index === payload.toIndex) {
          count++;
          card.priority = count;
          card.updatedAt = new Date();
          count++;
          continue;
        }
        card.priority = count;
        card.updatedAt = new Date();
        count++;
      }

      let dictionary: Dictionary<Story> = {};
      sortedCardsInTheNewColumn.forEach(item => {
        dictionary[item.id] = item;
      })

      const { id, priority, stageId } = payload.story;
      const stateStory = state.stories[id];
      const newStoriesIndex = cloneDeep(state.storiesIndex);
      const oldStoryIds = Object.keys(newStoriesIndex[stateStory.stageId]);

      let oldStageStories: Story[] = [];
      let newStageStories: Story[] = [];
      // move story to another list
      if (stateStory.stageId !== stageId) {
        newStoriesIndex[stateStory.stageId] = {};
        for (let i = 0; i < oldStoryIds.length; i++) {
          const storyId = oldStoryIds[i];
          if (
            storyId === stateStory.id ||
            !state.stories[storyId] ||
            !!state.stories[storyId].deletedAt
            // || !!state.stories[storyId].completedAt
          ) {
            continue;
          }
          newStoriesIndex[stateStory.stageId] = {
            ...newStoriesIndex[stateStory.stageId],
            [storyId]: 1,
          };
          oldStageStories.push(state.stories[storyId]);
        }
        oldStageStories.sort((a, b) => a.priority - b.priority);

        newStoriesIndex[stageId] = {
          ...newStoriesIndex[stageId],
          [id]: 1,
        };
        const newStoryIds = Object.keys(newStoriesIndex[stageId]);
        for (let i = 0; i < newStoryIds.length; i++) {
          const storyId = newStoryIds[i];
          if (
            storyId === stateStory.id ||
            !state.stories[storyId] ||
            !!state.stories[storyId].deletedAt
          ) {
            continue;
          }
          newStageStories.push(state.stories[storyId]);
        }
        newStageStories.sort((a, b) => a.priority - b.priority);
        newStageStories.splice(priority, 0, payload.story);
      }

      return {
        ...state,
        stories: {
          ...state.stories,
          ...oldCardsDictionary,
          ...dictionary,
          [payload.story.id]: { ...payload.story, updatedAt: new Date() },
        },
        internalUpdateDate: new Date(),
        storiesIndex: newStoriesIndex,
      }
    }

    case UPDATE_STORY_LOCALLY: {
      const { payload }: { payload: Story } = action;
      const { id, priority, stageId } = payload;
      const stateStory = state.stories[id];
      const newStoriesIndex = cloneDeep(state.storiesIndex);

      let oldStageStories: Story[] = [];
      let newStageStories: Story[] = [];
      if (
        stateStory &&
        stateStory.priority === priority &&
        stateStory.stageId === stageId
      ) {
        return {
          ...state,
          stories: {
            ...state.stories,
            [id]: { ...payload, updatedAt: new Date() },
          },
          internalUpdateDate: new Date(),
        };
      }

      if (stateStory) {
        const oldStoryIds = Object.keys(newStoriesIndex[stateStory.stageId]);
        // move story to another list
        if (stateStory.stageId !== stageId) {
          newStoriesIndex[stateStory.stageId] = {};
          for (let i = 0; i < oldStoryIds.length; i++) {
            const storyId = oldStoryIds[i];
            if (
              storyId === stateStory.id ||
              !state.stories[storyId] ||
              !!state.stories[storyId].deletedAt
              // || !!state.stories[storyId].completedAt
            ) {
              continue;
            }
            newStoriesIndex[stateStory.stageId] = {
              ...newStoriesIndex[stateStory.stageId],
              [storyId]: 1,
            };
            oldStageStories.push(state.stories[storyId]);
          }
          oldStageStories.sort((a, b) => a.priority - b.priority);

          newStoriesIndex[stageId] = {
            ...newStoriesIndex[stageId],
            [id]: 1,
          };
          const newStoryIds = Object.keys(newStoriesIndex[stageId]);
          for (let i = 0; i < newStoryIds.length; i++) {
            const storyId = newStoryIds[i];
            if (
              storyId === stateStory.id ||
              !state.stories[storyId] ||
              !!state.stories[storyId].deletedAt
            ) {
              continue;
            }
            newStageStories.push(state.stories[storyId]);
          }
          newStageStories.sort((a, b) => a.priority - b.priority);
          newStageStories.splice(priority, 0, payload);
        } else {
          // move to the same list
          for (let i = 0; i < oldStoryIds.length; i++) {
            const storyId = oldStoryIds[i];
            if (
              !state.stories[storyId] ||
              !!state.stories[storyId].deletedAt ||
              !!state.stories[storyId].completedAt
            ) {
              continue;
            }
            newStageStories.push(state.stories[storyId]);
          }
          newStageStories.sort((a, b) => a.priority - b.priority);
          newStageStories = arrayMove(
            newStageStories,
            stateStory.priority,
            priority,
          );
        }
      }
      oldStageStories = updateStoryPriorities(oldStageStories);
      newStageStories = updateStoryPriorities(newStageStories);
      if (priority === 999) {
        payload.priority = newStageStories.length - 1
      }
      return {
        ...state,
        focusStoryId: undefined,
        stories: {
          ...state.stories,
          ...[...oldStageStories, ...newStageStories].reduce(
            (obj, item) => ({
              ...obj,
              [item.id]: item,
            }),
            {},
          ),
          [id]: { ...payload, updatedAt: new Date() },
        },
        internalUpdateDate: new Date(),
        storiesIndex: newStoriesIndex,
      };
    }

    // sync:
    case SET_LAST_SYNCHRONIZED_AT: {
      return {
        ...state,
        lastSynchronizedAt: action.payload,
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};
