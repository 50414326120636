import dayjs from 'dayjs';

import esAntd from 'antd/locale/es_ES'
import ptAntd from 'antd/locale/pt_BR'
import usAntd from 'antd/locale/en_US'
import frAntd from 'antd/locale/fr_FR'
import jaAntd from 'antd/locale/ja_JP'
import deAntd from 'antd/locale/de_DE'
import ruAntd from 'antd/locale/ru_RU'
import hiAntd from 'antd/locale/hi_IN'
import koAntd from 'antd/locale/ko_KR'
import { Locale } from 'antd/lib/locale';
import { CDN } from './constants';

export const IMAGE_FLAGS = {
  en: `${CDN}/assets/images/flags/en.png`,
  es: `${CDN}/assets/images/flags/es.png`,
  fr: `${CDN}/assets/images/flags/fr.png`,
  de: `${CDN}/assets/images/flags/de.png`,
  hi: `${CDN}/assets/images/flags/hi.png`,
  ja: `${CDN}/assets/images/flags/ja.png`,
  ko: `${CDN}/assets/images/flags/ko.png`,
  pt: `${CDN}/assets/images/flags/pt.png`,
  ru: `${CDN}/assets/images/flags/ru.png`,
}

export interface Language {
  lang: string;
  langFlg: string;
  langFullName: string;
  currentAntdLocale: Locale;
  langShortValue: string;
}

export const GetLanguageMetadata = (lang: string): Language => {
  let langFlg = '';
  let langFullName = '';
  let currentAntdLocale = usAntd;
  let langShortValue = 'en';

  if (lang) {
    if (lang == 'en' || lang == 'en-US' || lang.startsWith('en-')) {
      langFlg = IMAGE_FLAGS.en;
      langFullName = 'English';
      currentAntdLocale = usAntd;
      dayjs.locale('en');
      langShortValue = 'en';
    }
    if (lang == 'es' || lang.startsWith('es-')) {
      langFlg = IMAGE_FLAGS.es;
      langFullName = 'Español';
      currentAntdLocale = esAntd;
      dayjs.locale('es');
      langShortValue = 'es';
    }
    if (lang == 'fr' || lang.startsWith('fr-')) {
      langFlg = IMAGE_FLAGS.fr;
      langFullName = 'Français';
      currentAntdLocale = frAntd;
      dayjs.locale('fr');
      langShortValue = 'fr';
    }
    if (lang == 'de' || lang.startsWith('de-')) {
      langFlg = IMAGE_FLAGS.de;
      langFullName = 'Deutsch';
      currentAntdLocale = deAntd;
      dayjs.locale('de');
      langShortValue = 'de';
    }
    if (lang == 'hi' || lang.startsWith('hi-')) {
      langFlg = IMAGE_FLAGS.hi;
      langFullName = 'हिंदी';
      currentAntdLocale = hiAntd;
      dayjs.locale('hi');
      langShortValue = 'hi';
    }
    if (lang == 'ja' || lang.startsWith('ja-')) {
      langFlg = IMAGE_FLAGS.ja;
      langFullName = '日本語';
      currentAntdLocale = jaAntd;
      dayjs.locale('ja');
      langShortValue = 'ja';
    }
    if (lang == 'ko' || lang.startsWith('ko-')) {
      langFlg = IMAGE_FLAGS.ko;
      langFullName = '한국인';
      currentAntdLocale = koAntd;
      dayjs.locale('ko');
      langShortValue = 'ko';
    }
    if (lang == 'pt' || lang.startsWith('pt-')) {
      langFlg = IMAGE_FLAGS.pt;
      langFullName = 'Português';
      currentAntdLocale = ptAntd;
      dayjs.locale('pt');
      langShortValue = 'pt';
    }
    if (lang == 'ru' || lang.startsWith('ru-')) {
      langFlg = IMAGE_FLAGS.ru;
      langFullName = 'Русский';
      currentAntdLocale = ruAntd;
      dayjs.locale('ru');
      langShortValue = 'ru';
    }
  }

  return {
    lang,
    langFlg,
    langFullName,
    currentAntdLocale,
    langShortValue,
  };
};
