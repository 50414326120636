import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ADD_BOARD_LOCALLY,
  ADD_STAGE_LOCALLY,
  ADD_STORY_LOCALLY,
  FOCUS_STORY_LOCALLY,
  RESET_STATE,
  SET_ACCOUNT_ID,
  SET_ACTIVE_BOARD,
  synchronize,
  Synchronize,
  useStoreContext,
  useSynchronize,
} from '../store';
import isNil from 'lodash-es/isNil';
import orderBy from 'lodash-es/orderBy';
import { useDatabase } from '../database/hooks';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { GetLanguageMetadata } from '../utils/lang';
import { setUserId } from 'firebase/analytics';
import { analytics, auth } from '../services/firebase';
import { Trigger } from '../typings/synchronize';
import { getAccountId, getUsersLanguage, isAnonymous } from '../utils/users';
import service from '../api/Service';
import { useNavigate } from 'react-router-dom';
import { PeriodCompletedCards } from '../board/ViewModels';
import { IDBPDatabase } from 'idb';
import { Database } from '../typings/database';
import { Locale } from 'antd/es/locale';
import { webContents } from '@todesktop/client-core';
import { readFile } from '@todesktop/client-fs';
import { colors } from '../theme';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import 'dayjs/locale/hi';
import 'dayjs/locale/ja';
import 'dayjs/locale/es';
import 'dayjs/locale/ru';
import 'dayjs/locale/ko';
import { GoogleAuthProvider, signInAnonymously, signInWithCredential } from 'firebase/auth';
import { ANONYMOUS } from '../typings/auth';
import SignupModal from '../account/SignupModal';
import { TEMPLATES } from '../utils/template';
import * as uuid from 'uuid';
import { Board } from '../typings/board';
import { useTranslation } from 'react-i18next';
import forEach from 'lodash-es/forEach';
import { createNewStory } from '../utils/story';
import { Stage } from '../typings/stage';
import { CredentialResponse } from 'google-one-tap';
import Service from '../api/Service';
import ReactGA from 'react-ga';
import { LogEvent } from '../utils/analytics';

type ContextType = {
  loading: boolean;
  freezeBackground: boolean;
  activeBoardId?: string;
  isBoldColors: boolean;
  isMenuExpanded: boolean;
  isCompletedCards: boolean;
  selectedPeriodCompletedCards: PeriodCompletedCards;
  showMyAccountPanel: boolean;
  database?: IDBPDatabase<Database>;
  currentAntDLocale: Locale;
  syncOperation?: Synchronize;
  setLoading: (boolean: boolean) => void;
  setIsBoldColors: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMenuExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  callbackSignInSucceeded: (accountId: string) => void;
  handleSignupClick: () => void;
  callbackOpenForgotHowToLoggedIn: () => void;
  handleChangeLanguage: (lang: string) => void;
  setShowMyAccountPanel: (value: boolean) => void;
  handleSignOutClick: () => void;
  handleMyAccountPanelToggle: (value?: boolean) => void;
  setFreezeBackground: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPeriodCompletedCards: React.Dispatch<
    React.SetStateAction<PeriodCompletedCards>
  >;
  setIsCompletedCards: React.Dispatch<React.SetStateAction<boolean>>;
  callbackForgotPasswordEmailSent: () => void;
  displaySignupModal: () => boolean;
  openSignupModalHandleClick: () => void;
};

const Context = createContext<ContextType>({
  loading: false,
  freezeBackground: false,
  isBoldColors: true,
  isMenuExpanded: true,
  isCompletedCards: false,
  selectedPeriodCompletedCards: {
    period: 1,
    periodType: 'Days',
  },
  showMyAccountPanel: false,
  currentAntDLocale: { locale: 'en' },
  setIsBoldColors: () => { },
  setIsMenuExpanded: () => { },
  setLoading: () => { },
  callbackSignInSucceeded: () => { },
  handleSignupClick: () => { },
  callbackOpenForgotHowToLoggedIn: () => { },
  handleChangeLanguage: () => { },
  setShowMyAccountPanel: () => { },
  handleSignOutClick: () => { },
  handleMyAccountPanelToggle: () => { },
  setFreezeBackground: () => { },
  setSelectedPeriodCompletedCards: () => { },
  setIsCompletedCards: () => { },
  callbackForgotPasswordEmailSent: () => { },
  displaySignupModal: () => true,
  openSignupModalHandleClick: () => { },
});

const REFRESH_EVERY_10_MINS = 10 * 60 * 1000;
const FREE_CARDS = 3
const isDevelopment = process.env.NODE_ENV !== 'production';

const AppContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { dispatch, state } = useStoreContext();
  const database = useDatabase();
  const syncOperation = useSynchronize();
  const navigate = useNavigate();
  let isDesktop = isDesktopApp();
  const { t } = useTranslation();

  const inactivityTimeout = useRef<NodeJS.Timeout | null>(null);

  var userLang = localStorage.getItem('lang') || navigator.language;
  const lang = GetLanguageMetadata(userLang);

  const [loading, setLoading] = useState(false);
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  const [isBoldColors, setIsBoldColors] = useState(true);
  const [showMyAccountPanel, setShowMyAccountPanel] = useState(false);
  const [freezeBackground, setFreezeBackground] = useState(false);
  const [isCompletedCards, setIsCompletedCards] = useState(false);
  const [selectedPeriodCompletedCards, setSelectedPeriodCompletedCards] =
    useState<PeriodCompletedCards>({
      period: 1,
      periodType: 'Days',
    });
  const [currentAntDLocale, setCurrentAntDLocale] = useState(
    lang.currentAntdLocale,
  );
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const [loadingSignupButtons, setLoadingSignupButtons] = useState(false);

  const handleSignupClick = async () => {
    setLoading(true);
    navigate('/signup');
    reloadData();
  };

  const reportDesktopLaunch = (distributor: string) => {
    // Check if "Launch" event has been sent
    let launch_key = 'td_kanbana_event_launch';
    let launch_check = localStorage.getItem(launch_key);
    if (!launch_check) {
      // If we have not sent the event, send it now
      fetch(
        'https://web-api.kanbana.com/desktopEvent?d=' +
        distributor +
        '&e=Launch',
      );
      localStorage.setItem(launch_key, 'sent');
    }
  };

  const clearInactivityTimeout = () => {
    if (inactivityTimeout.current) {
      clearTimeout(inactivityTimeout.current);
      inactivityTimeout.current = null;
    }
  };

  const setInactivityTimeout = () => {
    clearInactivityTimeout();

    inactivityTimeout.current = setTimeout(() => {
      window.location.reload();
    }, REFRESH_EVERY_10_MINS);
  };

  const resetInactivityTimeout = () => {
    clearInactivityTimeout();

    setInactivityTimeout();
  };

  const callbackForgotPasswordEmailSent = () => {
    navigate('/login');
    setLoading(true);
    reloadData();
  };

  useEffect(() => {
    tryToUpdateStateFromLocalStorage();

    if (isDesktopApp()) {
      webContents.setWindowOpenRules({
        // @ts-ignore
        ref: undefined, // web contents ref is optional, defaults to the current window.
        rules: [
          {
            regex: '.*google\\.com.*',
            options: {
              action: 'allow',
              overrideBrowserWindowOptions: {
                titleBarStyle: 'default',
                width: 550,
                height: 600,
              },
            },
          },
        ],
        fallback: {
          action: 'openInBrowser',
        },
      });

      // Check local storage for language
      let kl_key = 'kanbana_language';
      let kl_key_check = 'td_kanbana_language_loaded';
      let lang_check = localStorage.getItem(kl_key_check);
      if (!lang_check) {
        // If we have not loaded the language, load it now from the file and set it once only
        try {
          // Get distributor from install file
          let path = kl_key + '.txt';
          readFile('temp', path)
            .then((contents) => {
              let lang = contents;
              // Set distributor in local storage & state
              localStorage.setItem('lang', lang);
              localStorage.setItem(kl_key_check, 'loaded');
            })
            .catch((reason) => {
              console.log(reason);
            });
        } catch (e) {
          console.log(e);
        }
      }

      // Check local storage for distributor
      let kd_key = 'td_kanbana_distributor';
      let kd_date_key = 'td_kanbana_distributor_date';
      let kd_key_check = 'td_kanbana_distributor_loaded';
      let distributor_check = localStorage.getItem(kd_key_check);
      if (distributor_check) {
        // If we have already loaded the distributor, read it from local storage
        let distributor = localStorage.getItem(kd_key);
        if (distributor) {
          // Set distributor in state
          state.distributor = distributor;
        }
      } else {
        // If we have not loaded the distributor, load it now from the file
        try {
          // Get distributor from install file
          let path = 'kanbana_distributor.txt';
          readFile('temp', path)
            .then((contents) => {
              let distributor = contents;
              // Set distributor in local storage & state
              localStorage.setItem(kd_key, distributor);
              localStorage.setItem(kd_date_key, new Date().toDateString());
              state.distributor = distributor;
              localStorage.setItem(kd_key_check, 'loaded');
              reportDesktopLaunch(distributor);
            })
            .catch((reason) => {
              console.log(reason);
              reportDesktopLaunch('Unknown');
            });
        } catch (e) {
          console.log(e);
          reportDesktopLaunch('Unknown');
        }
      }
    }

    document.body.style.backgroundColor = colors.lightgrey;

    // Set up the inactivity timeout
    setInactivityTimeout();

    // Add event listeners to reset the inactivity timeout when the user interacts with the app
    document.addEventListener('mousemove', resetInactivityTimeout);
    document.addEventListener('keydown', resetInactivityTimeout);
    window.onbeforeunload = () => {
      localStorage.setItem('appState', JSON.stringify(state));
    };

    const savedState = localStorage.getItem('appState');
    if (savedState) {
      const localState = JSON.parse(savedState);
      setIsMenuExpanded(localState.isMenuExpanded);
    }
  }, []);

  const displaySignupModal = () => {
    if (Object.keys(state.stories).length >= FREE_CARDS && isAnonymous()) {
      setOpenSignupModal(true);
      return true;
    }
    return false;
  }

  const openSignupModalHandleClick = () => {
    setOpenSignupModal(true);
  }

  const handleSignOutClick = async () => {

    const total = localStorage.length;
    for (let index = total - 1; index >= 0; index--) {
      const element = localStorage.key(index) as string;
      if (
        element?.startsWith('firstTime') ||
        element?.startsWith('td_') ||
        element === 'lang'
      ) {
        continue;
      }

      localStorage.removeItem(element);
    }

    localStorage.removeItem("AppPlans");
    localStorage.removeItem("com.kanbana.account.id");

    dispatch({ type: RESET_STATE, payload: null });
    if (database) {
      await Promise.all([
        database.clear('boards'),
        database.clear('stages'),
        database.clear('stories'),
      ]);
    }

    // setLoading(true);
    setIsMenuExpanded(true);
    setIsBoldColors(true);
    setShowMyAccountPanel(false);
    setFreezeBackground(false);
    setIsCompletedCards(false);
    setSelectedPeriodCompletedCards({
      period: 1,
      periodType: 'Days',
    });

    reloadData();
    setLoading(false);
    navigate(!isDesktopApp() ? '/login' : '/signup');
  };

  const handleChangeLanguage = (lang: string) => {
    const { currentAntdLocale } = GetLanguageMetadata(lang);
    setLoading(true);
    setCurrentAntDLocale(currentAntdLocale);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const reloadData = () => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const initializeSync = async (accountId: string) => {
    try {

      const updatedBoards = Object.fromEntries(
        Object.entries(state.boards).map(([key, value]) => [
          key,
          { ...value, accountId: accountId }, // Replace `newAccountId` with the desired value
        ])
      );

      const updatedStages = Object.fromEntries(
        Object.entries(state.stages).map(([key, value]) => [
          key,
          { ...value, accountId: accountId }, // Replace `newAccountId` with the desired value
        ])
      );

      const updatedStories = Object.fromEntries(
        Object.entries(state.stories).map(([key, value]) => [
          key,
          { ...value, accountId: accountId }, // Replace `newAccountId` with the desired value
        ])
      );

      const initialBoard = Object.values(updatedBoards);
      const initialLists = Object.values(updatedStages);
      const initialCards = Object.values(updatedStories);

      const { boards } = await synchronize(
        database,
        dispatch,
        {
          accountId,
          trigger: Trigger.SignIn,
          pusher: 2,
        }, initialBoard, initialLists, initialCards);

      let activeBoardId = '';
      if (boards.length) {
        const storedJSON = localStorage.getItem(accountId);
        if (!storedJSON) {
          activeBoardId = orderBy(
            boards.filter((board) => board.deletedAt == null),
            (board) => board.priority,
          )[0].id;
          dispatch({
            type: SET_ACTIVE_BOARD,
            payload: activeBoardId,
          });
        } else {
          activeBoardId = (JSON.parse(storedJSON) as any)?.lastBoardId ?? '';
        }

        dispatch({
          type: SET_ACTIVE_BOARD,
          payload: activeBoardId,
        });
      }

      localStorage.setItem(
        'accountId',
        JSON.stringify({
          accountId,
          lastBoardId: activeBoardId,
        }),
      );

      const language = localStorage.getItem('lang') || navigator.language;
      const lang = GetLanguageMetadata(language);
      if (getUsersLanguage() !== lang.langShortValue) {
        service.changeLanguage(lang.langShortValue, accountId);
      }

    } finally {
      let accountvk = localStorage.getItem('accountId');
      let accountnk = localStorage.getItem('AccountInfo');
      console.log("accountvkaccountvkaccountvk", accountvk, accountnk)
      if (!accountvk || !accountnk) {
        handleSignOutClick();
      } else {
        setLoading(false);
        navigate('/board');
      }

      setOpenSignupModal(false);
    }
  };

  const createFirstBoardLocally = (accountId: string) => {
    setTimeout(() => {
      dispatch({
        type: SET_ACCOUNT_ID,
        payload: accountId,
      });

      const template = TEMPLATES[0]

      const timestamp = new Date();
      const id = uuid.v1();

      const newBoard: Board = {
        accountId: accountId,
        id,
        priority: 0,
        title: t(`MyFirstBoard.Name`),
        createdAt: timestamp,
        updatedAt: timestamp,
      };

      const columns = template.columns;

      dispatch({
        type: ADD_BOARD_LOCALLY,
        payload: newBoard,
      });

      let stages: Stage[] = []

      forEach(columns, (column) => {
        let stage = {
          id: uuid.v1(),
          boardId: id,
          isLast: true,
          priority: column.priority,
          storyLimit: column.storyLimit,
          title: t(`Template.${column.title}`),
          createdAt: timestamp,
          updatedAt: timestamp,
        }
        stages.push(stage)
        dispatch({
          type: ADD_STAGE_LOCALLY,
          payload: stage,
        });
      });

      dispatch({
        type: SET_ACTIVE_BOARD,
        payload: id,
      });

      const newStory = createNewStory(stages[stages.length - 1].id, {
        priority: 0,
        color: undefined,
      });
      dispatch({
        type: ADD_STORY_LOCALLY,
        payload: newStory,
      });
      dispatch({
        type: FOCUS_STORY_LOCALLY,
        payload: newStory,
      });
      //@ts-ignore
      if (isDesktopApp() && isAnonymous() && state.stories.length === 1) {
        LogEvent('addTaskClickedDesktopAnonymousN2');
      }
    }, 200);
  }

  const tryToUpdateStateFromLocalStorage = async () => {
    setLoading(true);
    let accountId = localStorage.getItem('com.kanbana.account.id');
    let hasAccessedByAnonymous = localStorage.getItem('td_kanbana_anonymous_access');
    if (isNil(accountId) && isNil(hasAccessedByAnonymous)) {
      if (isDesktopApp()) {
        try {
          await signInAnonymously(auth);
          accountId = ANONYMOUS + '-' + uuid.v1();
          localStorage.setItem('com.kanbana.account.id', accountId);
          localStorage.setItem('td_kanbana_anonymous_access', 'true');
          createFirstBoardLocally(accountId);
          setLoading(false);
        } catch (error) {
          handleSignOutClick();
          return;
        } finally {
          setLoadingSignupButtons(false);
        }
      } else {
        handleSignOutClick();
        setLoadingSignupButtons(false);
        return;
      }
    }

    if (isNil(accountId)) {
      handleSignOutClick();
    } else {
      callGetAccountInfo(accountId, 0);
    }
  };

  const callGetAccountInfo = async (accountId: string, attempts: number) => {
    try {
      initializeSync(accountId);

      setUserId(analytics, accountId);

      dispatch({
        type: SET_ACCOUNT_ID,
        payload: accountId,
      });
    } catch (e) {
      if ((e as { response?: { status?: number } })?.response?.status === 404) {
        handleSignOutClick();
      } else {
        if (attempts === 3) {
          handleSignOutClick();
        } else {
          callGetAccountInfo(accountId, attempts + 1);
        }
      }
    }
  };

  const callbackSignInSucceeded = useCallback((accountId: string) => {
    localStorage.setItem('com.kanbana.account.id', accountId);
    tryToUpdateStateFromLocalStorage();
  }, [tryToUpdateStateFromLocalStorage]);

  const callbackOpenForgotHowToLoggedIn = async () => {
    setLoading(true);
    navigate('forgothowtologgedin');
    reloadData();
  };

  const handleMyAccountPanelToggle = (value?: boolean) => {
    const nextValue = value !== undefined ? value : !showMyAccountPanel;

    setShowMyAccountPanel(nextValue);
    setFreezeBackground(nextValue);
  };

  const handleCredentialResponse = (response: CredentialResponse) => {
    // Build Firebase credential with the Google ID token.
    const idToken = response.credential;
    const credential = GoogleAuthProvider.credential(idToken);

    // Sign in with credential from the Google user.
    const result =
      signInWithCredential(auth, credential)
        .then(() => {
          const currentUser = auth.currentUser;
          if (currentUser) {
            setLoadingSignupButtons(true);
            currentUser
              .getIdToken(true)
              .then(function (idToken) {
                Service.CreateNewUser(idToken, currentUser.uid, response => {
                  if (response) {
                    callbackSignInSucceeded(response);
                    if (isDesktopApp()) {
                      // Only report the "Login" event if it occurred from the SignUp page
                      // Check if "Login" event has been sent
                      let login_key = "td_kanbana_event_login";
                      let login_check = localStorage.getItem(login_key);
                      if (!login_check) {
                        // If we have not sent the event, send it now
                        let distributor = localStorage.getItem("td_kanbana_distributor") ?? "Unknown";
                        fetch("https://web-api.kanbana.com/desktopEvent?d=" + distributor + "&e=Login");
                        localStorage.setItem(login_key, "sent");
                      }
                    }

                    ReactGA.event({
                      category: 'signIn',
                      action: 'signInSucceeded',
                    });
                  }
                });
              })
              .catch(function (error) {
                console.log('LOOK AT THE ERROR', error)
                alert(error);
              })
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The credential that was used.
          // const credential = firebase.auth.GoogleAuthProvider.credentialFromError(error);
          // ...
          console.log("ERROR with Google login", error);
          setLoadingSignupButtons(false);
        });
  };


  return (
    <Context.Provider
      value={{
        callbackSignInSucceeded: callbackSignInSucceeded,
        loading,
        setLoading,
        handleSignupClick,
        callbackOpenForgotHowToLoggedIn,
        freezeBackground,
        handleChangeLanguage,
        activeBoardId: state.activeBoardId,
        isBoldColors,
        isMenuExpanded,
        setShowMyAccountPanel,
        handleSignOutClick,
        setIsBoldColors,
        setIsMenuExpanded,
        handleMyAccountPanelToggle,
        setFreezeBackground,
        isCompletedCards,
        selectedPeriodCompletedCards,
        setSelectedPeriodCompletedCards,
        setIsCompletedCards,
        showMyAccountPanel,
        syncOperation,
        database,
        currentAntDLocale,
        callbackForgotPasswordEmailSent,
        displaySignupModal,
        openSignupModalHandleClick,
      }}>
      {children}
      <SignupModal
        open={openSignupModal}
        onCancel={() => setOpenSignupModal(false)}
        language={userLang ?? 'en'}
        isDesktop={isDesktop}
        callbackSignInSucceeded={callbackSignInSucceeded}
        handleCredentialResponse={handleCredentialResponse}
        loading={loadingSignupButtons}
      />
    </Context.Provider>
  );
};

const useAppContext = () => {
  return useContext(Context);
};

export { AppContextProvider, useAppContext };
