import { useEffect } from "react";
import { getDatabase, ref, query, limitToLast, onValue, set } from "firebase/database";
import { isNil } from "lodash";
import { useStoreContext, useSynchronize } from "../store";
import { database } from "../services/firebase";
import { Trigger } from "../typings/synchronize";

const useRealTimeMessageListener = () => {
  const store = useStoreContext();
  const synchronize = useSynchronize();

  useEffect(() => {
    const accountId = store.state.accountId;
    const syncId = sessionStorage.getItem('com.kanbana.syncId');

    if (isNil(accountId) || isNil(syncId)) return;

    const messagesRef = ref(database, `messages/${accountId}`);
    const latestMessageQuery = query(messagesRef, limitToLast(1));

    // Listen for new messages in real-time
    const unsubscribe = onValue(latestMessageQuery, (snapshot) => {
      const data = snapshot.val();
      console.log('data RECEIVED', data)
      if (data) {
        // Extract the first value from the object
        const latestMessage: any = Object.values(data)[0];
        if (
          // latestMessage.timestamp > openScreenDate &&
          latestMessage.syncId !== syncId
        ) {
          //   receiveMessageToSync();
          console.log('MESSAGE RECEIVED', latestMessage);
          synchronize({ accountId, trigger: Trigger.User, pusher: 1 });
        }
      }
    });

    // Cleanup listener when the hook is unmounted or dependencies change
    return () => {
      console.log('unsubscribe')
      unsubscribe();
    }
  }, [store.state.accountId]);
};

export default useRealTimeMessageListener;