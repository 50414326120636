import React from "react";
import { styled } from "styled-components";
import { buildKanbanaRedirectUrl } from "../utils/externalLink";

interface TermsAndConditionsProps {
  language: string;
  t?: any;
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ language, t }) => {
  const textArr = (t('Auth.Bottomtext') as string).split('{Terms of Service}');
  const secondPartArr = textArr[1].split('{Privacy Policy}');
  return (
    <>
      <TermsAndConditionsContainer>
        {textArr[0]}
        <a
          href={buildKanbanaRedirectUrl(language ?? 'en', '/terms')}
          target="_blank"
          style={{ color: 'black' }}>
          {' '}
          <u>
            {t('Premium.TermsService')}
          </u>
        </a>{' '}
      </TermsAndConditionsContainer>
      <TermsAndConditionsContainer>
        {secondPartArr[0]}
        {' '}
        <a
          href={buildKanbanaRedirectUrl(language ?? 'en', '/privacy')}
          target="_blank"
          style={{ color: 'black' }}>
          {' '}
          <u>
            {t('Premium.PrivacyPolicy')}
          </u>
        </a>
        {secondPartArr[1]}
      </TermsAndConditionsContainer>
    </>
  )
}

const TermsAndConditionsContainer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 10px;
  
`;