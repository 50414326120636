import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AppContextProvider } from './context/AppContext';
import Spinner from './resources/Spinner';
import useRealTimeMessageListener from './hooks/useRealTimeMessage';

const SignUpPage = React.lazy(() => import('./pages/signup'));
const LoginPage = React.lazy(() => import('./pages/login'));
const BoardPage = React.lazy(() => import('./pages/board'));
const ForgotHowLoggedInPage = React.lazy(() => import('./pages/forgotHowLoggedIn'));
const ForgotPasswordPage = React.lazy(() => import('./pages/forgotPassword'));

function RouteKan() {  
  
  useRealTimeMessageListener();

  return (
    <BrowserRouter>
      <AppContextProvider>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/board" element={<BoardPage />} />
            <Route path="/forgothowtologgedin" element={<ForgotHowLoggedInPage />} />
            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          </Routes>
        </Suspense>
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default RouteKan;