
import { Board } from '../typings/board';
import { Stage } from '../typings/stage';
import { Story } from '../typings/story';
import { KANBAN_PREMIUM } from '../utils/constants';
import { AccountResponse } from './Service';
import { generateInstance } from './httpClient';
import pako from 'pako';

export const getAccount = async (
  accountId: string,

): Promise<AccountResponse> => {
  try {
    const response = await generateInstance().get('accounts/' + accountId);
    const accountResponse: AccountResponse = {
      webTrialEndsAt: response.data.WebTrialEndsAt,
      canCopyBoard: response.data.Features.includes(KANBAN_PREMIUM),
    };
    return accountResponse;
  } catch (e) {
    throw e;
  }
};

export const deleteAccount = async (accountId: string): Promise<any> => {
  try {
    const response = await generateInstance().delete('accounts/' + accountId);
    return response;
  } catch (e) {
    throw e;
  }
};

export const getBoard = async (
  accountId: string,
  boardId: string,
): Promise<Board & {
  stages: Stage[];
}> => {
  try {
    const response = await generateInstance().post('Board', {
      AccountId: accountId,
      BoardId: boardId,
    });
    return mapBoardResponse(response.data);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getSharedBoard = async (
  token: string,
): Promise<Board & {
  stages: Stage[];
  stories: Story[];
}> => {
  try {
    const response = await generateInstance().get(`SharedBoard/${token}`);
    return mapBoardResponse(response.data);
  } catch (e) {
    console.error(e);
    throw e;
  }
};
// export const stripeService = async (
//   token: string
// ): Promise<AccountResponse> => {
//   try {
//     const response = await axios.get('api/subscription');
//     const accountResponse: AccountResponse = {

//       canCopyBoard: response.data.Features.includes(KANBAN_PREMIUM),
//     };
//     return accountResponse;
//   } catch (e) {
//     console.error(e);
//     throw e;
//   }
// };

export const synchronizeCompressed = async (
  { accountId, trigger, pusher }: { accountId: string; trigger: string; pusher?: number },
  boards: Board[] = [],
  stages: Stage[] = [],
  stories: Story[] = [],
  lastSynchronizedAt?: Date,
) => {
  let data1: any = localStorage.getItem('AccountInfo');

  try {
    // Send request to PostCompressed endpoint with correct headers
    const response = await generateInstance().post('/Synchronize/PostCompressed', {
      AccountId: accountId,
      Trigger: trigger,
      Pusher: pusher,
      LastSynchronizedAt: pusher === 1 ? null : lastSynchronizedAt,
      SynchronizedAt: new Date(),
      SyncData: {
        Boards: boards.map(mapBoardRequest),
        Stages: stages.map(mapStageRequest),
        Stories: stories.map(mapStoryRequest),
      },
    }, {
      responseType: 'arraybuffer', // Ensure we receive binary data
      headers: {
        'Accept-Encoding': 'gzip', // Ask server for GZip-compressed response
      },
    });

    // Decompress GZip response
    const decompressedData = pako.ungzip(response.data, { to: 'string' });

    // Parse the JSON data
    const data = JSON.parse(decompressedData);

    let accountInfo = data.SyncData.Account;
    if (accountInfo) {
      if (accountInfo['IsKanbanaPremiumEnabled'] === true) {
        accountInfo['subcode'] = 'h' + accountInfo['Id'] + 'qp';
      } else {
        accountInfo['subcode'] = 'al' + accountInfo['Id'] + 'h';
      }

      if (trigger === 'SignIn') {
        let data3: any = localStorage.getItem('AccountInfo');
        let newData = JSON.parse(data3);
        let data4: any = localStorage.getItem('syncData');
        let newData4 = JSON.parse(data4);
        let lastStoryLength = data.SyncData.Stories.length;
        let lastStoryData = data.SyncData.Stories;

        let syncData3 = lastStoryData;
        if (newData?.story > 0) {
          lastStoryLength = data.SyncData?.Stories.length + newData?.story;
        }
        if (syncData3.length === 0) {
          lastStoryData = newData4;
        }
        accountInfo['story'] = lastStoryLength;
        localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
        localStorage.setItem('syncData', JSON.stringify(lastStoryData));
      } else {
        let data: any = localStorage.getItem('AccountInfo');
        const accountInfo = JSON.parse(data);
        localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
      }
    }

    return {
      boards: data.SyncData.Boards.map(mapBoardResponse) as Board[],
      stages: data.SyncData.Stages.map(mapStageResponse),
      stories: data.SyncData.Stories.map(mapStoryResponse) as Story[],
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const synchronize = async (
  { accountId, trigger, pusher }: { accountId: string; trigger: string; pusher?: number },
  boards: Board[] = [],
  stages: Stage[] = [],
  stories: Story[] = [],
  lastSynchronizedAt?: Date,
) => {
  let data1: any = localStorage.getItem('AccountInfo')

  try {

    const response = await generateInstance().post('/Synchronize', {
      AccountId: accountId,
      Trigger: trigger,
      Pusher: pusher,
      LastSynchronizedAt: pusher === 1 ? null : lastSynchronizedAt,
      SynchronizedAt: new Date(),
      SyncData: {
        Boards: boards.map(mapBoardRequest),
        Stages: stages.map(mapStageRequest),
        Stories: stories.map(mapStoryRequest),
      },
    });

    const { data } = response;

    let accountInfo = data.SyncData.Account;
    if (accountInfo) {
      if (accountInfo['IsKanbanaPremiumEnabled'] === true) {
        accountInfo['subcode'] = 'h' + accountInfo['Id'] + 'qp'
      } else {
        accountInfo['subcode'] = 'al' + accountInfo['Id'] + 'h'
      }
      if (trigger === 'SignIn') {
        let data3: any = localStorage.getItem('AccountInfo')
        let newData = JSON.parse(data3)
        let data4: any = localStorage.getItem('syncData')
        let newData4 = JSON.parse(data4)
        let lastStoryLength = data.SyncData.Stories.length
        let lastStoryData = data.SyncData.Stories

        let syncData3 = lastStoryData
        if (newData?.story > 0) {
          lastStoryLength = data.SyncData?.Stories.length + newData?.story;
        }
        if (syncData3.length === 0) {

          lastStoryData = newData4
        }
        accountInfo['story'] = lastStoryLength;
        localStorage.setItem('AccountInfo', JSON.stringify(accountInfo))
        localStorage.setItem('syncData', JSON.stringify(lastStoryData))
      } else {
        let data: any = localStorage.getItem('AccountInfo')
        const accountInfo = JSON.parse(data)
        // accountInfo['story'] = data.SyncData.Stories.length +accountInfo['story']
        localStorage.setItem('AccountInfo', JSON.stringify(accountInfo))
      }
    }

    return {
      boards: data.SyncData.Boards.map(mapBoardResponse) as Board[],
      stages: data.SyncData.Stages.map(mapStageResponse),
      stories: data.SyncData.Stories.map(mapStoryResponse) as Story[],
    };
  } catch (e) {
    //  localStorage.clear()
    console.error(e);
    throw e;
  }
};

export const getAccountProvider = async (email: string) => {
  const myAxios = await generateInstance();
  return await myAxios.get(`/Accounts/GetUserProvider/${email}`)
    .then(x => x.data)
    .catch((e) => {
      if (e.response.status === 404) {
        return 'notFound';
      }
    });
};

export const mapStageRequest = (stage: Stage): any => ({
  Id: stage.id,
  BoardId: stage.boardId,
  Priority: stage.priority,
  Title: stage.title,

  CreatedAt: new Date(stage.createdAt!),
  UpdatedAt: new Date(stage.updatedAt!),
  DeletedAt: stage.deletedAt ? new Date(stage.deletedAt) : undefined,
});

export const mapStageResponse = (stage: any): Stage => ({
  id: stage.Id,
  boardId: stage.BoardId,
  priority: stage.Priority,
  storyLimit: stage.StoryLimit,
  title: stage.Title,

  createdAt: new Date(stage.CreatedAt),
  updatedAt: new Date(stage.UpdatedAt),
  deletedAt: stage.DeletedAt ? new Date(stage.DeletedAt) : undefined,
});

export const mapStoryRequest = (story: Story): any => ({
  Id: story.id,
  StageId: story.stageId,
  Color: story.color,
  Priority: story.priority,
  Title: story.title,

  CreatedAt: new Date(story.createdAt!),
  UpdatedAt: new Date(story.updatedAt!),
  CompletedAt: story.completedAt ? new Date(story.completedAt) : undefined,
  DeletedAt: story.deletedAt ? new Date(story.deletedAt) : undefined,
  DeadlineAt: story.deadlineAt ? new Date(story.deadlineAt) : undefined,
});

export const mapStoryResponse = (story: any) => ({
  id: story.Id,
  stageId: story.StageId,
  color: story.Color,
  priority: story.Priority,
  title: story.Title,

  createdAt: new Date(story.CreatedAt),
  updatedAt: new Date(story.UpdatedAt),
  deletedAt: story.DeletedAt ? new Date(story.DeletedAt) : undefined,
  completedAt: story.CompletedAt ? new Date(story.CompletedAt) : undefined,
  deadlineAt: story.DeadlineAt ? new Date(story.DeadlineAt) : undefined,
});

export const mapBoardRequest = (board: Board): any => ({
  Id: board.id,
  AccountId: board.accountId,
  Priority: board.priority,
  Title: board.title,

  CreatedAt: board.createdAt,
  UpdatedAt: board.updatedAt,
  DeletedAt: board.deletedAt,
});

export const mapBoardResponse = (board: any) => {
  const stages: Stage[] = [];
  const stories: Story[] = [];

  if (board.Stages) {
    for (let i = 0; i < board.Stages.length; i++) {
      const stage = board.Stages[i];
      stages.push(mapStageResponse(stage));
      for (let j = 0; j < stage.Stories.length; j++) {
        const story = stage.Stories[j];
        stories.push(mapStoryResponse(story));
      }
    }
  }

  return {
    id: board.Id,
    accountId: board.AccountId, // FIXME: server not returning accountId for this api
    priority: board.Priority,
    title: board.Title,

    stages,
    stories,

    createdAt: new Date(board.CreatedAt),
    updatedAt: new Date(board.UpdatedAt),
    deletedAt: board.DeletedAt ? new Date(board.DeletedAt) : undefined,
  };
};
