// operations are actions which have complex logic (included async call or multiple dispatch calls)
import { Dispatch } from 'react';
import { IDBPDatabase } from 'idb';

import * as AsyncService from '../api/AsyncService';
import { saveBoards, saveStages, saveStories } from '../database/utils';
import { LocalStorage } from '../typings/app';
import { Board } from '../typings/board';
import { Database } from '../typings/database';
import { Stage } from '../typings/stage';
import { Action } from '../typings/state';
import { Story } from '../typings/story';
import {
  LOAD_BOARDS_SUCCESS,
  LOAD_STAGES_SUCCESS,
  LOAD_STORIES_SUCCESS,
  SET_ACCOUNT_ID,
  SET_ACTIVE_BOARD,
  SET_LAST_SYNCHRONIZED_AT,
} from './actions';
import { setUserId } from 'firebase/analytics';
import { analytics } from '../services/firebase';
import isNil from 'lodash/isNil';

export const populateStoreFromDB = async (
  database: IDBPDatabase<Database> | undefined,
  dispatch: Dispatch<Action>,
) => {
  if (!database) {
    return;
  }
  const [boards, stages, stories] = await Promise.all([
    database.getAll('boards'),
    database.getAll('stages'),
    database.getAll('stories'),
  ]);
  dispatch({
    type: LOAD_BOARDS_SUCCESS,
    payload: boards,
  });
  dispatch({
    type: LOAD_STAGES_SUCCESS,
    payload: stages,
  });
  dispatch({
    type: LOAD_STORIES_SUCCESS,
    payload: stories,
  });

  const accountId = localStorage.getItem('com.kanbana.account.id');
  if (!accountId || !boards.length) {
    return;
  }
  let activeBoardId: string = '';
  try {
    const jsonData = localStorage.getItem(accountId);
    if (jsonData) {
      const storage: LocalStorage = JSON.parse(jsonData);
      if (storage.lastBoardId) {
        activeBoardId = storage.lastBoardId;
      }
    } else {
      activeBoardId = boards[0].id;
    }
  } catch (e) {
    console.error(e);
  }
  if (activeBoardId) {
    setUserId(analytics, accountId);
    dispatch({
      type: SET_ACCOUNT_ID,
      payload: accountId,
    });
    dispatch({
      type: SET_ACTIVE_BOARD,
      payload: activeBoardId,
    });
  }
};

export type RestoreData = {
  accountId: string;
  completedCards: Story[];
  deletedCards: Story[];
  deletedColumns: Stage[];
  deletedBoards: Board[];
};

export const getDataToBeRestored = async (
  accountId: string,
  database: IDBPDatabase<Database> | undefined,
): Promise<RestoreData> => {
  if (!database) {
    return {
      accountId,
      completedCards: [],
      deletedCards: [],
      deletedColumns: [],
      deletedBoards: [],
    };
  }
  const [boards, stages, stories] = await Promise.all([
    (await database.getAll('boards')).filter(x => x.accountId === accountId),
    database.getAll('stages'),
    (await database.getAll('stories')),
  ]);
  const accountStages = stages.filter(stage =>
    boards.some(board => board.id === stage.boardId),
  );
  const completedStories = stories
    .filter(story => accountStages.some(stage => stage.id === story.stageId) && !isNil(story.completedAt) && isNil(story.deletedAt))
    .sort((a, b) => {
      if (!a?.completedAt || !b?.completedAt) {
        return 0;
      } else {
        return b.completedAt.getTime() - a.completedAt.getTime();
      }
    });

  const deletedStories = stories
    .filter(story => accountStages.some(stage => stage.id === story.stageId) && !isNil(story.deletedAt))
    .sort((a, b) => {
      if (!a?.deletedAt || !b?.deletedAt) {
        return 0;
      } else {
        return b.deletedAt.getTime() - a.deletedAt.getTime();
      }
    });

  const deletedStages = accountStages
    .filter(x => !!x.deletedAt)
    .sort((a, b) => {
      if (!a?.deletedAt || !b?.deletedAt) {
        return 0;
      } else {
        return b.deletedAt.getTime() - a.deletedAt.getTime();
      }
    });

  const deletedBoards = boards
    .filter(x => !!x.deletedAt)
    .sort((a, b) => {
      if (!a?.deletedAt || !b?.deletedAt) {
        return 0;
      } else {
        return b.deletedAt.getTime() - a.deletedAt.getTime();
      }
    });

  return {
    accountId,
    completedCards: completedStories,
    deletedCards: deletedStories,
    deletedColumns: deletedStages,
    deletedBoards,
  };
};

export const synchronize = async (
  database: IDBPDatabase<Database> | undefined,
  dispatch: Dispatch<Action>,
  { accountId, trigger, pusher }: { accountId: string; trigger: string; pusher?: number },
  updatedBoards?: Board[],
  updatedStages?: Stage[],
  updatedStories?: Story[],
  lastSynchronizedAt?: Date,
) => {
  if (database) {
      updatedBoards?.length && saveBoards(database, updatedBoards);
      updatedStages?.length && saveStages(database, updatedStages);
      updatedStories?.length && saveStories(database, updatedStories);
  }
  try {
    const { boards, stages, stories } = await AsyncService.synchronizeCompressed(
      {
        accountId,
        trigger,
        pusher,
      },
      updatedBoards,
      updatedStages,
      updatedStories,
      lastSynchronizedAt,
    );
    dispatch({
      type: SET_LAST_SYNCHRONIZED_AT,
      payload: new Date(),
    });
    dispatch({
      type: LOAD_BOARDS_SUCCESS,
      payload: boards,
    });
    dispatch({
      type: LOAD_STAGES_SUCCESS,
      payload: stages,
    });
    dispatch({
      type: LOAD_STORIES_SUCCESS,
      payload: stories,
    });
    if (database) {
      saveBoards(database, boards);
      saveStages(database, stages);
      saveStories(database, stories);
    }
    return { boards, stages, stories };
    // return {boards: [], stages: [], stories: []}
  } catch (e) {
    throw e;
  }
};
