import {
  BoardViewModel,
  StageViewModel,
  StoryViewModel,
} from '../board/ViewModels';
import { CopyBoardResponse } from '../typings/board';
import { KANBAN_PREMIUM } from '../utils/constants';
import i18n from 'i18next';
import { generateInstance } from './httpClient';
import { getAccountId, isAnonymous, setUsersLanguage } from '../utils/users';
import { AccountInfo } from '../account/AccountPanel';
import { ANONYMOUS } from '../typings/auth';

export type SignInResponse = {
  signInVerified: boolean;
  deviceVerified: boolean;
  accountId: string;
  message?: string;
};

let thankyou1: any = localStorage.getItem('AccountInfo.IsKanbanaPremiumEnabled')
// console.log()
if (thankyou1 === true) {
  localStorage.setItem('thankyou', 'true')
}
else {
  localStorage.setItem('thankyou', 'false')
}

//todo pri2: errors should be handeled prettier and showed to the user instead of only being logged


const signIn = (
  token: string,
  success: (signInResponse: SignInResponse) => void,
) => {
  // let userLang = localStorage.getItem('lang') ||  localStorage.setItem('lang', navigator.language)
  let splitName = navigator.language.split('-')
  let userLang = localStorage.getItem('lang') || localStorage.setItem('lang', splitName[0])
  generateInstance()
    .post('SignIn', {
      Token: token,
    })
    .then(response => {
      const data = response.data;

      const signInResponse: SignInResponse = {
        signInVerified: data.SignInVerified,
        deviceVerified: data.DeviceVerified,
        accountId: data.AccountId,
        message: data.Message,
      };
      success(signInResponse);
      var userLang = localStorage.getItem('lang') || navigator.language;
      i18n.changeLanguage(userLang);
    })
    .catch(error => {
      console.error(error);
    });

};

const cancelSubscription = (
  accountId: string,
  success: () => void,
) => {
  generateInstance()
    .post('FastSpring/CancelSubscription', {
      AccountId: accountId,
    })
    .then(response => {
      const accountInfo = response.data;

      if (accountInfo['IsKanbanaPremiumEnabled'] === true) {
        accountInfo['subcode'] = 'h' + accountInfo['Id'] + 'qp'
      } else {
        accountInfo['subcode'] = 'al' + accountInfo['Id'] + 'h'
      }

      localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
      success();
    })
    .catch(error => {
      console.error(error);
    });
};

const upgradeSubscription = (
  accountId: string,
  success: () => void,
) => {
  generateInstance()
    .post('FastSpring/UpgradeSubscription', {
      AccountId: accountId,
    })
    .then(response => {
      const accountInfo = response.data;

      if (accountInfo['IsKanbanaPremiumEnabled'] === true) {
        accountInfo['subcode'] = 'h' + accountInfo['Id'] + 'qp'
      } else {
        accountInfo['subcode'] = 'al' + accountInfo['Id'] + 'h'
      }

      localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
      success();
    })
    .catch(error => {
      console.error(error);
    });
};

const CreateNewUser = (
  token: string,
  uuId: string,
  success: (accountId: string) => void,
) => {
  generateInstance()
    .post('SignIn/SignInNewUser', {
      Token: token,
      Uuid: uuId
    })

    .then(response => {
      const data = response.data;
      let accountInfo = data as AccountInfo;
      if (accountInfo['IsKanbanaPremiumEnabled'] === true) {
        accountInfo['subcode'] = 'h' + accountInfo['Id'] + 'qp'
      } else {
        accountInfo['subcode'] = 'al' + accountInfo['Id'] + 'h'
      }
      localStorage.setItem('AccountInfo', JSON.stringify(accountInfo))
      success(accountInfo.Id);
      var userLang = localStorage.getItem('lang') || navigator.language;
      i18n.changeLanguage(userLang);
    })
    .catch(error => {
      console.error(error);
    });
}

const Account = (
  token: string,
  uuId: string,

  success: (signInResponse: SignInResponse) => void,
) => {

  // let userLang = localStorage.getItem('lang') ||  localStorage.setItem('lang', navigator.language)
  let splitName = navigator.language.split('-')
  let userLang = localStorage.getItem('lang') || localStorage.setItem('lang', splitName[0])
  generateInstance()
    .post('Accounts', {
      Token: token,
      Uuid: uuId
    }, {
      headers: {
        "X-Client-LanguageCode": userLang || navigator.language
      }
    })

    .then(response => {
      const data = response.data;
      const signInResponse: SignInResponse = {
        signInVerified: true,
        deviceVerified: true,
        accountId: data.Id,
      };
      success(signInResponse);
      var userLang = localStorage.getItem('lang') || navigator.language;
      i18n.changeLanguage(userLang);

    })
    .catch(error => {
      console.error(error);
    });

};


export type AccountResponse = {
  webTrialEndsAt?: number;
  canCopyBoard: boolean;
};

const getAccount = (
  accountId: string,
  success: (accountResponse: AccountResponse) => void,
  signout: () => void,
) => {
  generateInstance()
    .get('accounts/' + accountId)
    .then(response => {

      const accountResponse: AccountResponse = {
        webTrialEndsAt: response.data.WebTrialEndsAt,
        canCopyBoard: response.data.Features.includes(KANBAN_PREMIUM),
      };
      success(accountResponse);
    })
    .catch(error => {

      console.error(error);
      if (error.response.status === 403) {
        signout();
      }
    });
};

export type ShareTokenResponse = {
  boardTitle: string;
  shareToken: string;
};

const getShareToken = (
  boardId: string,
  success: (shareTokenResponse: ShareTokenResponse) => void,
) => {
  generateInstance()
    .get('boards/' + boardId + '/shareToken')
    .then(response => {
      const shareTokenResponse: ShareTokenResponse = {
        boardTitle: response.data.BoardTitle,
        shareToken: response.data.Token,
      };
      success(shareTokenResponse);
    })
    .catch(error => {
      console.error(error);
    });
};
export type CreateBoardResponse = {
  boardTitle: string;
  boardId: string;
};

const createBoard = (
  accountId: string,
  title: string,
  success: (createBoardResponse: CreateBoardResponse) => void,
) => {
  generateInstance()
    .put('boards', {
      AccountId: accountId,
      Title: title,
    })
    .then(response => {
      const createBoardResponse: CreateBoardResponse = {
        boardTitle: response.data.BoardTitle,
        boardId: response.data.BoardId,
      };
      success(createBoardResponse);
    })
    .catch(error => {
      console.error(error);
    });
};

const copyBoard = (
  boardId: string,
  title: string,
  copyTasks: boolean,
  success: (copyBoardResponse: CopyBoardResponse) => void,
) => {
  localStorage.setItem('shareToken', 'true')
  generateInstance()
    .post('boards/' + boardId + '/copy', {
      BoardTitle: title,
      CopyStories: copyTasks,
    })
    .then(response => {
      localStorage.setItem('shareToken', 'false')
      const copyBoardResponse: CreateBoardResponse = {
        boardTitle: response.data.BoardTitle,
        boardId: response.data.BoardId,
      };
      success(copyBoardResponse);
    })
    .catch(error => {
      localStorage.setItem('shareToken', 'false')
      console.error(error);
    });
};

const fastSpringProcessOrder = (
  accountId: string,
  orderId: string,
  success: (hasSuccess: boolean) => void,
) => {
  generateInstance()
    .post('FastSpring/CreateAccountId', {
      AccountId: accountId,
      OrderId: orderId,
    })
    .then(response => {
      success(true);
    })
    .catch(error => {
      console.error(error);
      success(false);
    });
}

const getBoards = (
  accountId: string,
  boardId: string,
  success: (boardViewModels: BoardViewModel[]) => void,
) => {
  generateInstance()
    .post('Boards', {
      AccountId: accountId,
      BoardId: boardId,
    })
    .then(response => {
      const boards = response.data.Boards;

      const boardViewModels: BoardViewModel[] = [];
      boards.forEach((board: any) => {
        //console.debug('board: ' + board.Title);

        const stageViewModels: StageViewModel[] = [];

        if (board.Stages) {
          board.Stages.forEach((stage: any) => {
            //console.debug('stage: ' + stage.Title);

            const storyViewModels: StoryViewModel[] = [];
            stage.Stories.forEach((story: any) => {
              //console.debug('story: ' + story.Title)

              const storyViewModel = new StoryViewModel();
              storyViewModel.initialize(
                story.Id,
                story.CreatedAt,
                story.UpdatedAt,
                story.DeletedAt,
                story.Title,
                story.Priority,
                story.Color,
                story.CompletedAt,
                story.StageId,
              );

              storyViewModels.push(storyViewModel);
            });

            const index = board.Stages.findIndex((s: any) => s.Id === stage.Id);
            const isLastStage = board.Stages.length - 1 === index;

            const stageViewModel = new StageViewModel();
            stageViewModel.initialize(
              stage.Id,
              stage.CreatedAt,
              stage.UpdatedAt,
              stage.DeletedAt,
              stage.Title,
              stage.Priority,
              stage.StoryLimit,
              stage.BoardId,
              storyViewModels,
            );
            stageViewModel.isLast = isLastStage;

            stageViewModels.push(stageViewModel);
          });
        }
        const boardViewModel = new BoardViewModel();
        boardViewModel.initialize(
          board.Id,
          board.CreatedAt,
          board.UpdatedAt,
          board.DeletedAt,
          board.Title,
          board.AccountId,
          stageViewModels,
          false,
        );

        boardViewModels.push(boardViewModel);
      });
      success(boardViewModels);
    })
    .catch(error => {
      console.error(error);
    });
};

const mapBoardResponse = (response: any) => {
  const board = response.data;
  const stageViewModels: StageViewModel[] = [];

  if (board.Stages) {
    board.Stages.forEach((stage: any) => {
      //console.debug('stage: ' + stage.Title);

      const storyViewModels: StoryViewModel[] = [];
      stage.Stories.forEach((story: any) => {
        //console.debug('story: ' + story.Title)

        const storyViewModel = new StoryViewModel();
        storyViewModel.initialize(
          story.Id,
          story.CreatedAt,
          story.UpdatedAt,
          story.DeletedAt,
          story.Title,
          story.Priority,
          story.Color,
          story.CompletedAt,
          story.StageId,
        );

        storyViewModels.push(storyViewModel);
      });

      const index = board.Stages.findIndex((s: any) => s.Id === stage.Id);
      const isLastStage = board.Stages.length - 1 === index;

      const stageViewModel = new StageViewModel();
      stageViewModel.initialize(
        stage.Id,
        stage.CreatedAt,
        stage.UpdatedAt,
        stage.DeletedAt,
        stage.Title,
        stage.Priority,
        stage.StoryLimit,
        stage.BoardId,
        storyViewModels,
      );
      stageViewModel.isLast = isLastStage;

      stageViewModels.push(stageViewModel);
    });
  }
  const boardViewModel = new BoardViewModel();
  boardViewModel.initialize(
    board.Id,
    board.CreatedAt,
    board.UpdatedAt,
    board.DeletedAt,
    board.Title,
    board.AccountId,
    stageViewModels,
    false,
  );

  return boardViewModel;
};
const getBoard = (
  accountId: string,
  boardId: string,
  onSuccess: (boardViewModel: BoardViewModel) => void,
  onError?: (error: Error) => void,
) => {
  generateInstance()
    .post('Board', {
      AccountId: accountId,
      BoardId: boardId,
    })
    .then(response => {
      onSuccess(mapBoardResponse(response));
    })
    .catch(error => {
      console.error(error);
      onError && onError(error);
    });
};

const getSharedBoard = (
  token: string,
  onSuccess: (boardViewModel: BoardViewModel) => void,
  onError?: (error: Error) => void,
) => {
  generateInstance()
    .get('SharedBoard/' + token)
    .then(response => {
      onSuccess(mapBoardResponse(response));
    })
    .catch(error => {
      console.error(error);
      onError && onError(error);
    });
};

//todo pri2: think about if it would be better to handle different kind of sync jobs so that the
//result would be a full board so that it visually had the ability to incremental update?
const syncStories = (
  accountId: string,
  storyViewModels: StoryViewModel[],
  success: (storyViewModels: StoryViewModel[]) => void,
) => {

  const timestamp = new Date();
  const storyDTOs: any[] = [];

  storyViewModels.forEach(storyViewModel => {
    const storyDTO = {
      CreatedAt: storyViewModel.createdAt,
      Id: storyViewModel.id,
      Priority: storyViewModel.priority,
      Color: storyViewModel.color,
      StageId: storyViewModel.stageId,
      Title: storyViewModel.title,
      CompletedAt: storyViewModel.completedAt,
      DeletedAt: storyViewModel.deletedAt,
      UpdatedAt: timestamp,
    };
    storyViewModel.updatedAt = timestamp;
    storyDTOs.push(storyDTO);
  });
  generateInstance()
    .post('Synchronize', {
      LastSynchronizedAt: timestamp, //to ignore the response
      Trigger: 'User',
      AccountId: accountId,
      SynchronizedAt: timestamp,
      SyncData: {
        Stories: storyDTOs,
        Boards: [],
        Stages: [],
      },
    })
    .then(response => {
      success(storyViewModels);

    })
    .catch(error => {
      // localStorage.clear();
      console.error(error);

    });
};


export const saveReceipt = async ({
  accountId,
  receipt,
  isIos,
  platform,
  paymentKey,
  success,
}: {
  accountId: string;
  receipt: string;
  isIos: boolean;
  platform: string;
  paymentKey: string;
  success: (hasSuccess: boolean) => void
}) => {
  try {
    const response = await generateInstance().post('/Receipt', {
      Receipt: receipt,
      AccountId: accountId,
      IsIos: isIos,
      Platform: platform,
      PaymentKey: paymentKey,
    });
    const { data } = response;
    success(true);
    return {
      activeProducts: data.ActiveProducts as PaymentResult[],
      status: data.Status as string,
    };
  } catch (error) {
    success(false);
    // Sentry.captureException(error);
    throw error;
  }
}

//todo pri2: think about if it would be better to handle different kind of sync jobs so that the
//result would be a full board so that it visually had the ability to incremental update?
const syncStages = (
  accountId: string,
  stageViewModels: StageViewModel[],
  success: (stageViewModels: StageViewModel[]) => void,
) => {
  localStorage.setItem('shareToken', 'true')
  console.log('warn reddd')
  const timestamp = new Date();

  const stageDTOs: any[] = [];

  stageViewModels.forEach(stageViewModel => {
    const storyDTO = {
      CreatedAt: stageViewModel.createdAt,
      Id: stageViewModel.id,
      Priority: stageViewModel.priority,
      BoardId: stageViewModel.boardId,
      Title: stageViewModel.title,
      DeletedAt: stageViewModel.deletedAt,
      UpdatedAt: timestamp.toISOString(),
      StoryLimits: stageViewModel.storyLimit,
    };
    stageDTOs.push(storyDTO);
  });
  generateInstance()
    .post('Synchronize', {
      LastSynchronizedAt: timestamp.toISOString(), //to ignore the response
      Trigger: 'User',
      AccountId: accountId,
      SynchronizedAt: timestamp.toISOString(),
      SyncData: {
        Stories: [],
        Boards: [],
        Stages: stageDTOs,
      },
    })
    .then(response => {
      success(stageViewModels);

    })
    .catch(error => {
      //localStorage.clear();
      console.error(error);

    });
};

const getFastSpringUserPlans = async (
  accountId: string
) => {
  try {
    const accountId = getAccountId();
    if (accountId.includes(ANONYMOUS)) {
      return {
        plansIds: ["kanbana-premium-yearly", "kanbana-premium-monthly"]
      }
    }
    const result = await generateInstance().get('FastSpring/GetUsersPlans/' + accountId);
    return {
      plansIds: result.data.PlanIds,
    }
  } catch (err) {
    console.info('err', err)
  }
}

const validateFastSpringCoupon = async (
  accountId: string,
  coupon: string,
  success: (hasSuccess: boolean) => void
) => {
  try {
    const result = await generateInstance().post('FastSpring/ValidateFastSpringCoupon', {
      accountId,
      coupon
    });
    if (result.status === 200) {
      success(true);
    } else {
      success(false);
    }
  } catch (err) {
    success(false);
  }
}

const updateName = async (
  accountId: string,
  coupon: string,
  success: (hasSuccess: boolean) => void
) => {
  try {
    const result = await generateInstance().post('Accounts/updateName', {
      accountId,
      name
    });
    if (result.status === 200) {
      success(true);
    } else {
      success(false);
    }
  } catch (err) {
    success(false);
  }
}

const sendForgotPasswordEmail = (
  email: string,
  succeded: () => void,
  failed: (message: string) => void,
) => {
  generateInstance()
    .post('SendForgotPasswordEmail', {
      Email: email,
    })
    .then(response => {
      succeded();
    })
    .catch(error => {
      if (error.response.status === 409) {
        failed(error.response.data.Message);
      } else {
        console.error(error);
      }
    });
};

//return stories with updated priority
const updateStoryPriorities = (
  storyViewModels: StoryViewModel[],
): StoryViewModel[] => {
  const timestamp = new Date();

  const updatedStoryViewModels: StoryViewModel[] = [];
  let newPriority = 0;
  storyViewModels.forEach(story => {
    if (story.priority !== newPriority) {
      //console.debug('update priority for story: "' + story.title + '" from ' + story.priority + ' to ' + newPriority);
      story.priority = newPriority;
      story.updatedAt = timestamp;
      updatedStoryViewModels.push(story);
    }
    ++newPriority;
  });
  return updatedStoryViewModels;
};

//return stages with updated priority
const updateStagePriorities = (
  stageViewModels: StageViewModel[],
): StageViewModel[] => {
  const timestamp = new Date();

  const updatedStageViewModels: StageViewModel[] = [];
  let newPriority = 0;
  stageViewModels.forEach(stage => {
    if (stage.priority !== newPriority) {
      //console.debug('update priority for stage: "' + stage.title + '" from ' + stage.priority + ' to ' + newPriority);
      stage.priority = newPriority;
      stage.updatedAt = timestamp;
      updatedStageViewModels.push(stage);
    }
    ++newPriority;
  });
  return updatedStageViewModels;
};

const changeEmailNotification = async (
  value: boolean,
  success: (success: boolean, value: boolean) => void,
  accountId?: string,
) => {
  try {
    const result = await generateInstance().post('accounts/changeEmailNotification', {
      value, accountId
    });
    if (result.status === 200) {
      success(true, value);
    } else {
      success(false, false);
    }
  } catch (err) {
    success(false, false);
  }
}

const changeLanguage = async (
  language: string,
  accountId?: string
) => {
  if (!isAnonymous()) {
    await generateInstance().post('accounts/updatePreferebleLanguage', {
      language,
      accountId
    });
  }

  setUsersLanguage(language);
}

export type PaymentResult = {
  ProductIdentifier: string;
  ProductName: string;
  Expires?: Date;
};

export default {
  signIn,
  Account,
  getFastSpringUserPlans,
  getAccount,
  getBoards,
  getBoard,
  createBoard,
  copyBoard,
  getShareToken,
  getSharedBoard,
  syncStories,
  syncStages,
  sendForgotPasswordEmail,
  updateStoryPriorities,
  updateStagePriorities,
  fastSpringProcessOrder,
  saveReceipt,
  cancelSubscription,
  upgradeSubscription,
  validateFastSpringCoupon,
  changeEmailNotification,
  changeLanguage,
  updateName,
  CreateNewUser,
};
